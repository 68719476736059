import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

interface NewMessageState {
  category: number | undefined;
}

const initialState: NewMessageState = {
  category: undefined
};

export const newMessageSlice = createSlice({
  name: 'newMessage',
  initialState,
  reducers: {
    setCategory: (state, action: PayloadAction<number>) => {
      state.category = action.payload;
    }
  }
});

export const { setCategory } = newMessageSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectNewMessageState = (state: RootState) => state.newMessage;

export default newMessageSlice.reducer;
