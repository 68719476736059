import { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Tag, AttachmentTile } from 'common/components';
import { getDaysAgo, formatDateToLongString } from 'common/utils/dates';
import { CategoryListConfig } from 'common/constants';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { markMessageAsConfirmed, selectActiveMessageState } from 'redux/activeMessage';
import parse from 'html-react-parser';
import { Button } from '@mui/material';
import { useMarkMessageAsConfirmedMutation } from 'redux/api';
import { AdminPanelMessage, InboxMessage } from 'features/messages';
import { markMessageAsConfirmedAndUpdateMessages } from 'redux/messages';
import { selectAccount } from 'redux/auth';
import { AuthRoles } from 'features/accounts';
import { MessageViewRecipients } from './MessageViewReicpients';

export function ActiveMessageView() {
  const { activeMessage } = useAppSelector(selectActiveMessageState);
  const user = useAppSelector(selectAccount);
  const [confirmMessage] = useMarkMessageAsConfirmedMutation();
  const dispatch = useAppDispatch();

  if (!activeMessage) {
    return <MessageViewWrapper />;
  }

  const categoryData = CategoryListConfig.find(({ id }) => activeMessage.category === id);

  const fortmattedCreatedAtDate = formatDateToLongString(activeMessage.addedAt);
  const agoLabel = getDaysAgo(activeMessage.addedAt);
  const combinedDateLabel = `${fortmattedCreatedAtDate} (${agoLabel})`;

  const handleButtonClick = async () => {
    dispatch(markMessageAsConfirmed());
    dispatch(markMessageAsConfirmedAndUpdateMessages({ id: activeMessage.id }));
    await confirmMessage({ id: activeMessage.id });
  };

  return (
    <MessageViewWrapper>
      {categoryData && (
        <Tag
          color={categoryData.categoryColor}
          label={categoryData.label}
          sx={{ mr: (theme) => theme.spacing(1) }}
          key={`${categoryData.label}`}
        />
      )}
      <Typography
        variant="subtitle1"
        sx={{
          color: 'grey.800',
          py: (theme) => theme.spacing(1)
        }}
      >
        {combinedDateLabel}
      </Typography>
      <Typography
        variant="h5"
        sx={{
          fontWeight: '600',
          pb: (theme) => theme.spacing(1)
        }}
      >
        {activeMessage.title}
      </Typography>
      <Box sx={{ height: '16px', mt: 1, display: 'flex', alignItems: 'center', position: 'relative' }}>
        {user?.role === AuthRoles.Admin && (
          <MessageViewRecipients recipients={(activeMessage as AdminPanelMessage).recipients} />
        )}
      </Box>
      <Box
        sx={{
          fontFamily: (theme) => theme.typography.fontFamily,
          height: '70%',
          overflowY: 'auto',
          pb: 2
        }}
      >
        {parse(activeMessage.content)}
      </Box>
      {activeMessage.attachments?.map((attachment) => (
        <a
          style={{ textDecoration: 'none' }}
          href={attachment.filepath}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          <AttachmentTile showFullWidth fileName={attachment.originalFilename} key={attachment.id} />
        </a>
      ))}
      {user?.role !== AuthRoles.Admin &&
        activeMessage.isAcceptanceMandatory &&
        !(activeMessage as InboxMessage).isConfirmed && (
          <Button
            onClick={() => handleButtonClick()}
            sx={{
              typography: 'body1',
              fontWeight: '600',
              borderRadius: 2,
              border: '1.5px solid',
              borderColor: 'grey.800',
              backgroundColor: 'transparent',
              textTransform: 'none',
              px: 3.5,
              color: 'black',
              '&:hover': {
                backgroundColor: 'grey.800',
                color: 'common.white'
              }
            }}
          >
            Oznacz jako przeczytane
          </Button>
        )}
    </MessageViewWrapper>
  );
}

const MessageViewWrapper = ({ children }: { children?: ReactNode }) => {
  return (
    <Box
      sx={{
        pt: 2,
        pb: 0.5,
        px: 2,
        backgroundColor: 'grey.200',
        width: '100%'
      }}
    >
      <Box height="100%" width="100%" borderRadius={2} bgcolor="white" px={4} pt={7}>
        {children}
      </Box>
    </Box>
  );
};
