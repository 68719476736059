export const relativeDateFormatter = new Intl.RelativeTimeFormat('pl');

export const formatDateToLongString = (date: Date | string) =>
  new Date(date).toLocaleString('pl-PL', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  });

export const getDeltaDays = (date: Date) => (date.getTime() - Date.now()) / (1000 * 3600 * 24);

export const getDaysAgo = (date: Date | string) =>
  relativeDateFormatter.format(Math.round(getDeltaDays(new Date(date))), 'days');
