import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from './store';

interface ModalState {
  isOpen: boolean;
}

const initialState: ModalState = {
  isOpen: false
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state) => {
      state.isOpen = true;
    },
    closeModal: (state) => {
      state.isOpen = false;
    },
    toggleModal: (state) => {
      state.isOpen = !state.isOpen;
    }
  }
});

export const { openModal, closeModal, toggleModal } = modalSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectModalState = (state: RootState) => state.modal.isOpen;

export default modalSlice.reducer;
