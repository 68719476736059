import { Grid } from '@mui/material';

import { AttachmentTile } from 'common/components';

interface AttachmentContainerProps {
  attachments: Array<File>;
  handleRemoveAttachment: (name: string) => void;
}

export const AttachmentContainer = ({ attachments, handleRemoveAttachment }: AttachmentContainerProps) => {
  return (
    <Grid container justifyContent="flex-start" spacing={1} sx={{ overflowY: 'auto', height: '50px' }}>
      {attachments.map((attachment, index) => (
        <Grid item key={index}>
          <AttachmentTile
            fileName={attachment.name}
            fileSize={attachment.size}
            handleRemoveAttachment={handleRemoveAttachment}
          />
        </Grid>
      ))}
    </Grid>
  );
};
