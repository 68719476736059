import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { ActiveMessageView } from 'common/components';
import { MessageEditor } from 'features/messages/messageCreation';

export function MessageDemo() {
  return (
    <Grid container spacing={2} sx={{ height: '600px' }}>
      <Grid item xs={6}>
        <Typography variant="subtitle1">Editor</Typography>
        <MessageEditor onEditorStateChange={() => {}} />
      </Grid>

      <Grid item xs={6}>
        <Typography variant="subtitle1">View</Typography>
        <ActiveMessageView />
      </Grid>
    </Grid>
  );
}
