import { BaseQueryFn, createApi, FetchArgs } from '@reduxjs/toolkit/query/react';

import { ResponseError } from 'common/types';
import {
  AccountsResponse,
  AuthAccount,
  CreateAccountRequest,
  ForgotPasswordRequest,
  GetAccountsParams,
  VerifyEmailRequest
} from 'features/accounts';
import { baseQueryWithReauth } from 'redux/authHelpers';

export const accountsApi = createApi({
  reducerPath: 'accountsApi',
  baseQuery: baseQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, ResponseError>,
  endpoints: (builder) => ({
    getAccounts: builder.query<AccountsResponse, GetAccountsParams>({
      query: (params) => ({ url: 'accounts', params })
    }),
    createAccount: builder.mutation<AuthAccount, CreateAccountRequest>({
      query: (body) => ({
        url: 'accounts',
        headers: {
          'content-type': 'application/json'
        },
        method: 'POST',
        body
      })
    }),
    forgotPassword: builder.mutation<void, ForgotPasswordRequest>({
      query: (body) => ({
        url: 'accounts/forgot-password',
        headers: {
          'content-type': 'application/json'
        },
        method: 'POST',
        body
      })
    }),
    verifyEmail: builder.mutation<void, VerifyEmailRequest>({
      query: (body) => ({
        url: 'accounts/verify-email',
        headers: {
          'content-type': 'application/json'
        },
        method: 'POST',
        body
      })
    })
  })
});

export const { useLazyGetAccountsQuery, useCreateAccountMutation, useForgotPasswordMutation, useVerifyEmailMutation } =
  accountsApi;
