import { Box, TextField, TextFieldProps } from '@mui/material';

export const UnderlinedInput = ({ placeholder, label, ...rest }: TextFieldProps) => (
  <TextField
    {...rest}
    InputProps={{
      ...rest.InputProps,
      placeholder: placeholder,
      ref: rest.InputProps?.ref,
      autoFocus: true,
      sx: {
        paddingLeft: '0px !important',
        borderRadius: 0,
        border: 'none',
        '& fieldset': {
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          borderBottom: '1.5px solid grey.400',
          borderRadius: '0'
        },
        '& .MuiInputBase-input': {
          py: 1
        },
        fontWeight: 600,
        fontSize: 20
      },
      startAdornment: (
        <>
          <Box sx={{ fontWeight: 400, fontSize: 20, color: 'grey.800', mr: 0.5 }}>
            <span>{label}</span>
          </Box>
          {rest && rest.InputProps && rest.InputProps.startAdornment}
        </>
      ),
      endAdornment: <>{rest && rest.InputProps && rest.InputProps.endAdornment}</>
    }}
  />
);
