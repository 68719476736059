import { AdminAddressGroupsPanelPage } from 'pages/adminPage/AdminAddressGroupsPanelPage';
import { AdminMessagesPanelPage } from 'pages/adminPage/AdminMessagesPanelPage';
import { AdminPage } from 'pages/adminPage/AdminPage';
import { AdminUsersPanelPage } from 'pages/adminPage/AdminUsersPanelPage';
import { RouteProps } from 'react-router-dom';
import { RoutesDefinition } from './RoutesDefinition';

export const adminRoutes: RouteProps[] = [
  {
    path: RoutesDefinition.admin,
    element: <AdminPage />
  },
  {
    path: RoutesDefinition.adminMessagesPanel,
    element: <AdminMessagesPanelPage />
  },
  {
    path: RoutesDefinition.adminUsersPanel,
    element: <AdminUsersPanelPage />
  },
  {
    path: RoutesDefinition.adminAddressGroupsPanel,
    element: <AdminAddressGroupsPanelPage />
  }
];
