import { AlertColor } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from './store';

interface ToastsState {
  isOpen: boolean;
  severity: AlertColor;
  message: string;
}

const initialState: ToastsState = {
  isOpen: false,
  severity: 'info',
  message: ''
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showToast: (
      state,
      { payload: { severity, message } }: PayloadAction<{ severity: AlertColor; message: string }>
    ) => {
      state.isOpen = true;
      state.severity = severity;
      state.message = message;
    },
    hideToast: (state) => {
      state.isOpen = false;
    }
  }
});

export const { showToast, hideToast } = toastSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectToastSlice = (state: RootState) => state.toast;

export default toastSlice.reducer;
