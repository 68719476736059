import { createApi } from '@reduxjs/toolkit/query/react';
import { GetPharmaciesQueryParams, GetPharmaciesResponse } from 'features/pharmacies';
import { baseQueryWithReauth } from 'redux/authHelpers';

export const pharmaciesApi = createApi({
  reducerPath: 'pharmaciesApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getPharmacies: builder.query<GetPharmaciesResponse, GetPharmaciesQueryParams>({
      query: (params) => ({ url: 'pharmacies', params })
    })
  })
});

export const { useLazyGetPharmaciesQuery } = pharmaciesApi;
