import { createApi } from '@reduxjs/toolkit/query/react';
import { MessageCreationData } from 'common/components';

import {
  GetAdminMessagesRequestParams,
  GetAdminMessagesResponse,
  GetInboxMessagesRequestParams,
  InboxMessagesResponse,
  SendMessageResponse,
  SeeOrConfirmMessageRequest
} from 'features/messages';
import { baseQueryWithReauth } from 'redux/authHelpers';

const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const messagesApi = createApi({
  reducerPath: 'messagesApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    sendMessage: builder.mutation<SendMessageResponse, MessageCreationData>({
      query: (body) => ({
        url: 'messages',
        credentials: 'include',
        body: Object.keys(body).reduce((formData, key) => {
          const bodyKey = key as keyof typeof body;
          if (bodyKey === 'attachments') {
            body[bodyKey].forEach((value: File, index: number) => {
              formData.append(`${capitalize(bodyKey)}[${index}].FileName`, value.name);
              formData.append(`${capitalize(bodyKey)}[${index}].File`, value);
            });
          } else if (bodyKey === 'recipients') {
            body[bodyKey].forEach((value, index) => {
              formData.append(`${capitalize(bodyKey)}[${index}]`, value);
            });
          } else {
            formData.append(capitalize(bodyKey), `${body[bodyKey]}`);
          }
          return formData;
        }, new FormData()),
        method: 'POST',
        headers: {
          'Content-Type': undefined
        }
      })
    }),
    getAdminMessages: builder.query<GetAdminMessagesResponse, GetAdminMessagesRequestParams>({
      query: (params) => ({ url: 'messages/all', params })
    }),
    getInboxMessages: builder.query<InboxMessagesResponse, GetInboxMessagesRequestParams>({
      query: (params) => ({ url: 'messages/mine', params })
    }),
    markMessageAsSeen: builder.mutation<void, SeeOrConfirmMessageRequest>({
      query: (body) => ({ url: `messages/${body.id}/seen`, credentials: 'include', method: 'PUT' })
    }),
    markMessageAsConfirmed: builder.mutation<void, SeeOrConfirmMessageRequest>({
      query: (body) => ({ url: `messages/${body.id}/confirmed`, credentials: 'include', method: 'PUT' })
    })
  })
});

export const {
  useSendMessageMutation,
  useLazyGetAdminMessagesQuery,
  useLazyGetInboxMessagesQuery,
  useMarkMessageAsSeenMutation,
  useMarkMessageAsConfirmedMutation
} = messagesApi;
