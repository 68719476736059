import { Grid, Typography } from '@mui/material';

import { ColorfulTagIcon, ColorfulTagIconProps } from 'common/components/CategoryList';

interface CategoryItemProps {
  label: string;
  categoryColor: string;
  setActive: () => void;
  isActive: boolean;
  isMain?: boolean;
  icon?: ColorfulTagIconProps;
  counter?: number;
}

export const CategoryItem = ({
  label,
  categoryColor,
  setActive,
  isActive,
  isMain,
  icon,
  counter
}: CategoryItemProps) => {
  return (
    <Grid
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        my: 1,
        p: 2,
        borderRadius: 2,
        backgroundColor: `${isActive && (isMain ? categoryColor : `${categoryColor}50`)}`,
        position: 'relative',

        '&:hover': {
          backgroundColor: `${isMain ? categoryColor : `${categoryColor}50`}`,
          cursor: 'pointer',

          '& > div > p': {
            fontWeight: 600
          }
        }
      }}
      onClick={setActive}
    >
      <Grid sx={{ mr: 1.3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {icon ? (
          <ColorfulTagIcon tagColor={icon.tagColor} component={icon.component} />
        ) : (
          <ColorfulTagIcon tagColor={categoryColor} />
        )}
      </Grid>
      <Typography variant="body1" fontWeight={`${isActive ? 600 : 500}`}>
        {label}
      </Typography>
      {counter !== undefined && counter > 0 && (
        <Grid
          sx={{
            backgroundColor: 'primary.main',
            py: 0.5,
            px: 1,
            borderRadius: 6,
            typography: 'body1',
            fontWeight: 600,
            color: 'common.white',
            position: 'absolute',
            right: (theme) => theme.spacing(2),
            top: '50%',
            transform: ' translateY(-50%)'
          }}
        >
          {counter}
        </Grid>
      )}
    </Grid>
  );
};
