import { Box } from '@mui/material';
import { AuthRoles } from 'features/accounts';
import { LogoColumn } from 'layouts/components';
import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectAccount } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { RoutesDefinition } from 'routing/constants/RoutesDefinition';

interface PanelLayoutProps {
  logoColumnComponents?: ReactNode;
  topBarComponents?: ReactNode;
  isAdminRoute?: boolean;
  children: ReactNode;
}

export const PanelLayout = ({ logoColumnComponents, topBarComponents, isAdminRoute, children }: PanelLayoutProps) => {
  const account = useAppSelector(selectAccount);
  const navigate = useNavigate();

  useEffect(() => {
    if (!account) {
      navigate(RoutesDefinition.login);
    } else if (account.role !== AuthRoles.Admin && isAdminRoute) {
      navigate(RoutesDefinition.inbox);
    } else if (account.role === AuthRoles.Admin && !isAdminRoute) {
      navigate(RoutesDefinition.adminBasePath + RoutesDefinition.adminMessagesPanel);
    }
  }, [account, navigate, isAdminRoute]);

  return (
    <Box sx={{ display: 'flex', width: '100vw', height: '100vh' }} component="section">
      <LogoColumn>{logoColumnComponents}</LogoColumn>
      <Box display="flex" flexDirection="column" width="100%">
        {topBarComponents && <Box height="7.75%">{topBarComponents}</Box>}
        <Box display="flex" height={topBarComponents ? '92.25%' : '100%'}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};
