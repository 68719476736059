import { z } from 'zod';
import { errors } from 'common/constants';

export const userCreationSchema = z.object({
  name: z.string().nonempty(errors.required),
  pharmacyId: z.string().nonempty(errors.required),
  email: z.string().superRefine((val, ctx) => {
    const fieldValidationResult = z.string().nonempty(errors.required).email(errors.incorrectEmail).safeParse(val);

    if (!fieldValidationResult.success) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: errors.incorrectEmail
      });
    }
  }),
  phone: z.string().superRefine((val, ctx) => {
    const fieldValidationResult = z.string().nonempty(errors.required).regex(/^\d+$/).min(9).max(11).safeParse(val);

    if (!fieldValidationResult.success) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: errors.incorrectPhone
      });
    }
  }),
  role: z.number().superRefine((val, ctx) => {
    const fieldValidationResult = z.number().positive(errors.required).safeParse(val);

    if (!fieldValidationResult.success) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: errors.required
      });
    }
  })
});

export type UserCreationData = z.infer<typeof userCreationSchema>;
