import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Provider as ReduxProvider } from 'react-redux';

import store from 'redux/store';
import Routing from 'routing/Routing';
import theme from 'common/Theme';
import { ToastNotification } from 'common/components';

import 'common/styles/global.css';

function App() {
  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <Routing />
          <ToastNotification />
        </Router>
      </ThemeProvider>
    </ReduxProvider>
  );
}

export default App;
