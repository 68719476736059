import { Chip } from '@mui/material';
import { useController } from 'react-hook-form';

interface ChipInputButtonProps {
  btnValue: number;
  name: string;
  label: string;
  variant?: 'outlined' | 'filled' | undefined;
}

export const ChipInputButton = ({ btnValue, name, label, variant }: ChipInputButtonProps) => {
  const {
    field: { ref, onChange, value, ...fieldProps },
    fieldState: { error }
  } = useController({
    name
  });

  return (
    <Chip
      {...fieldProps}
      label={label}
      variant={variant}
      ref={ref}
      sx={{
        backgroundColor: btnValue === value ? 'grey.800' : '',
        color: btnValue === value ? 'common.white' : 'grey.800',
        borderColor: error && 'error.main',
        typography: 'body1',
        border: '2px solid',

        '&.MuiChip-clickable:hover': {
          backgroundColor: 'grey.800',
          color: 'common.white'
        }
      }}
      onClick={() => onChange(btnValue)}
    />
  );
};
