export enum Roles {
  NONE = 0,
  ADMIN = 2,
  MODERATOR = 4,
  OWNER = 8,
  EMPLOYEE = 16
}

export enum AuthRoles {
  None = 'None',
  Admin = 'Admin',
  Moderator = 'Moderator',
  Owner = 'Owner',
  Employee = 'Employee'
}

export interface Account {
  id: string;
  name: string;
  email: string;
  pharmacyName: string;
}

export interface NestedAccount {
  id: string;
  name: string;
  email: string;
  role: Roles;
}

export interface AuthAccount {
  id: string;
  phone: string;
  name: string;
  email: string;
  role: string;
  created: string;
  updated: string;
  isVerified: boolean;
  jwtToken: string;
  refreshToken: string;
}

export enum AuthRolesLocalized {
  None = 'Brak',
  Admin = 'Administrator',
  Moderator = 'Moderator',
  Owner = 'Właściciel',
  Employee = 'Apteczne'
}

export const getRoleNameBasedOnEnum = (role: Roles) => {
  switch (role) {
    case Roles.NONE: {
      return AuthRolesLocalized.None;
    }
    case Roles.ADMIN: {
      return AuthRolesLocalized.Admin;
    }
    case Roles.MODERATOR: {
      return AuthRolesLocalized.Moderator;
    }
    case Roles.OWNER: {
      return AuthRolesLocalized.Owner;
    }
    case Roles.EMPLOYEE: {
      return AuthRolesLocalized.Employee;
    }
  }
};

export const getColorBasedOnLocalizedText = (role: string) => {
  switch (role) {
    case AuthRolesLocalized.None: {
      return '';
    }
    case AuthRolesLocalized.Admin: {
      return '#EBF3FF';
    }
    case AuthRolesLocalized.Moderator: {
      return '#ffb3d1';
    }
    case AuthRolesLocalized.Owner: {
      return '#FCE8E6';
    }
    case AuthRolesLocalized.Employee: {
      return '#b3ffe6';
    }
  }
};
