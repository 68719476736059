import { useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import { RoutesDefinition } from 'routing/constants/RoutesDefinition';
import { ForgotPassword } from 'features/accounts/forgetPassword';
import { useForgotPasswordMutation } from 'redux/api';
import { ForgetPasswordData } from 'features/accounts';

export const ForgotPasswordPage = () => {
  const [forgotPassword, { isLoading, isSuccess, error }] = useForgotPasswordMutation();
  const navigate = useNavigate();

  const handleSubmit: SubmitHandler<ForgetPasswordData> = async (requestData) => {
    try {
      await forgotPassword(requestData);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(RoutesDefinition.login);
    }
  }, [navigate, isSuccess]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ width: '100vw', height: '100vh' }}
      columns={{ xs: 4, md: 12 }}
    >
      <ForgotPassword onSubmit={handleSubmit} isProcessing={isLoading} error={error} />
    </Grid>
  );
};
