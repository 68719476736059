import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import modalReducer from './modal';
import toastReducer from './toast';
import messageCreationModalReducer from './messageCreationModal';
import creationModalReducer from './creationModal';
import activeMessageReducer from './activeMessage';
import newMessageReducer from './newMessage';
import messagesReducer from './messages';

import authReducer from './auth';
import { messagesApi, accountsApi, pharmaciesApi, authApi, addressGroupsApi } from 'redux/api';

const store = configureStore({
  reducer: {
    modal: modalReducer,
    toast: toastReducer,
    messageCreationModal: messageCreationModalReducer,
    creationModal: creationModalReducer,
    activeMessage: activeMessageReducer,
    newMessage: newMessageReducer,
    messages: messagesReducer,
    auth: authReducer,
    // Add the generated reducers as a specific top-level slice
    [authApi.reducerPath]: authApi.reducer,
    [messagesApi.reducerPath]: messagesApi.reducer,
    [accountsApi.reducerPath]: accountsApi.reducer,
    [pharmaciesApi.reducerPath]: pharmaciesApi.reducer,
    [addressGroupsApi.reducerPath]: addressGroupsApi.reducer
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      messagesApi.middleware,
      accountsApi.middleware,
      pharmaciesApi.middleware,
      authApi.middleware,
      addressGroupsApi.middleware
    )
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
