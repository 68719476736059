import { RootState } from 'redux/store';

import { PrepareHeaders } from 'common/types';
import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { authApi } from './api';

export const authHeaders: PrepareHeaders = (headers, { getState }) => {
  // By default, if we have a token in the store, let's use that for authenticated requests
  const token = (getState() as RootState).auth.account?.jwtToken;
  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }
  return headers;
};

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URI
});

const baseQueryWithAuth = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URI,
  prepareHeaders: authHeaders
});
export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let result = await baseQueryWithAuth(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const refreshResult = await baseQueryWithAuth({ url: 'accounts/refresh-token', method: 'POST' }, api, {
      credentials: 'include'
    });
    if (!refreshResult.error?.status) {
      // retry the initial query
      result = await baseQueryWithAuth(args, api, extraOptions);
    } else {
      api.dispatch(authApi.endpoints.logout);
    }
  }
  return result;
};
