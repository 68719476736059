import { Grid } from '@mui/material';

import { CategoryItemData } from 'common/types';
import { CategoryItem } from './CategoryItem';

interface CategoryListProps {
  categories: Array<CategoryItemData>;
  activeCategory: number;
  handleCategoryChange: (value: number) => void;
}

export const CategoryList = ({ categories, activeCategory, handleCategoryChange }: CategoryListProps) => {
  return (
    <Grid sx={{ width: '100%' }}>
      {categories.map((category) => (
        <CategoryItem
          label={category.label}
          categoryColor={category.categoryColor}
          setActive={() => handleCategoryChange(category.id)}
          isActive={activeCategory === category.id}
          isMain={category.isMain}
          icon={category.icon}
          counter={category.counter}
          key={category.id}
        />
      ))}
    </Grid>
  );
};
