import { Grid, Link } from '@mui/material';

import Logo from 'common/images/logo.svg';
import { ReactNode } from 'react';
import { RoutesDefinition } from 'routing/constants/RoutesDefinition';

interface LogoColumnProps {
  children?: ReactNode;
}

export const LogoColumn = ({ children }: LogoColumnProps) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      borderRight={'2px solid'}
      borderColor={'grey.200'}
      sx={{
        px: 2.5,
        height: '100%',
        maxWidth: '265px'
      }}
    >
      <Grid sx={{ px: 4, py: 3, mb: 4 }}>
        <Link
          href={RoutesDefinition.landing}
          sx={{ display: 'flex', justifyContent: 'center' }}
          title="Alfarm logo"
          aria-label="Alfarm logo"
        >
          <Grid
            component="img"
            sx={{
              '&:hover': {
                cursor: 'pointer'
              }
            }}
            src={Logo}
            alt="Alfarm Logo"
          />
        </Link>
      </Grid>
      {children}
    </Grid>
  );
};
