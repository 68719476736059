import { useEffect, useState } from 'react';
import draftToHtml from 'draftjs-to-html';

import { Box, Checkbox, FormControlLabel, FormGroup, Modal, SxProps, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MinimizeIcon from '@mui/icons-material/Minimize';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { openModal } from 'redux/modal';
import { showToast } from 'redux/toast';
import { useLazyGetAccountsQuery, useSendMessageMutation } from 'redux/api';
import {
  closeMessageCreationModal,
  selectMessageCreationModalState,
  toggleMessageCreationModalMinimized
} from 'redux/messageCreationModal';

import {
  ButtonAttachment,
  AttachmentContainer,
  MultipleOptionsAutocomplete,
  messageCreationSchema,
  MessageCreationData
} from 'common/components';
import theme from 'common/Theme';
import { checkIfNotExceedSize } from 'common/utils/fileSize';
import { MessageEditor } from 'features/messages/messageCreation';
import { UnderlinedInput } from 'common/components/UnderlinedInput';
import { CategoryPicker } from 'features/messages/messageCreation/components';
import { SubmitButton } from 'common/components/Form';
import { transformAccountToAutoCompleteOption } from 'features/accounts';

export const MessageCreationModal = () => {
  const { isOpen, isMinimized } = useAppSelector(selectMessageCreationModalState);
  const [attachments, setAttachments] = useState<Array<File>>([]);
  const [sendMessage, { isSuccess, isError }] = useSendMessageMutation();
  const [fetchAccountsTrigger] = useLazyGetAccountsQuery();
  const [isFormValid, setFormValid] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const formMethods = useForm<MessageCreationData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(messageCreationSchema),
    defaultValues: {
      title: '',
      recipients: [],
      attachments: [],
      content: '',
      isAcceptanceMandatory: false
    }
  });

  useEffect(() => {
    if (isSuccess) {
      formMethods.reset();
      setAttachments([]);
      dispatch(showToast({ severity: 'success', message: 'Pomyślnie wysłano wiadomość!' }));
      dispatch(closeMessageCreationModal());
    }
    if (isError) {
      dispatch(showToast({ severity: 'error', message: 'Wystąpił błąd podczas dodawania wiadomości' }));
    }
  }, [isSuccess, isError, formMethods, dispatch]);

  const onSubmit = async (data: MessageCreationData) => {
    await sendMessage(data);
  };

  useEffect(() => {
    formMethods.setValue('attachments', attachments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments]);

  useEffect(() => {
    setFormValid(formMethods.formState.isValid);
  }, [formMethods.formState.isValid]);

  const handleClose = () => {
    dispatch(openModal());
  };

  const handleBarClick = () => {
    if (isMinimized) {
      handleMinimize();
    }
  };

  const handleMinimize = (e?: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e?.stopPropagation();
    dispatch(toggleMessageCreationModalMinimized());
  };

  const handleUsersSearch = async (query: string) => {
    const response = await fetchAccountsTrigger({ search: query });
    if (response.data) {
      return transformAccountToAutoCompleteOption(response.data);
    }
    return [];
  };

  const handleAttachment = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    const fiftyMBInBytes = 52428800;
    if (target.files) {
      const fileListAsArray = Array.from(target.files);
      checkIfNotExceedSize(fileListAsArray, fiftyMBInBytes, () =>
        dispatch(
          showToast({
            severity: 'error',
            message: 'File cannot exceed 50MB'
          })
        )
      );
      setAttachments([...fileListAsArray, ...attachments]);
    }
  };

  const handleRemoveAttachment = (attachmentName: string) => {
    setAttachments(attachments.filter((attachment) => attachment.name !== attachmentName));
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-messageModal-title"
      aria-describedby="modal-messageModal-description"
      sx={modalStyle}
      hideBackdrop
    >
      <Box sx={boxStyle(isMinimized)}>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit((data) => onSubmit({ ...data }))}>
            <Box onClick={handleBarClick} padding={theme.spacing(2, 4)} sx={headingStyle}>
              <Typography variant="h6">Nowa wiadomość</Typography>
              <Box display="flex" alignItems="center">
                <MinimizeIcon onClick={(e) => handleMinimize(e)} sx={{ cursor: 'pointer', mr: 1 }} />
                <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
              </Box>
            </Box>
            <Box sx={{ pt: 2, px: 4, pb: 4, display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Controller
                name="recipients"
                control={formMethods.control}
                render={({ field }) => (
                  <MultipleOptionsAutocomplete
                    sx={{ mb: 1 }}
                    searchFunction={(query: string) => handleUsersSearch(query)}
                    placeholder="Wyszukaj"
                    label="Do:"
                    handleValueChange={(value) => field.onChange(value.map((option) => option.id))}
                  />
                )}
              />
              <Controller
                name="title"
                control={formMethods.control}
                render={({ field: { ref, ...rest } }) => (
                  <UnderlinedInput {...rest} fullWidth label="Temat:" sx={{ my: 1 }} />
                )}
              />
              <Controller
                name="category"
                control={formMethods.control}
                render={({ field }) => <CategoryPicker handleOnClick={field.onChange} />}
              />
              <Controller
                name="content"
                control={formMethods.control}
                render={({ field: { ref, onChange, ...rest } }) => (
                  <MessageEditor {...rest} onChange={(value) => onChange(draftToHtml(value))} />
                )}
              />
              <AttachmentContainer attachments={attachments} handleRemoveAttachment={handleRemoveAttachment} />
              <Box display="flex" justifyContent="space-between">
                <FormGroup>
                  <Controller
                    name="isAcceptanceMandatory"
                    control={formMethods.control}
                    render={({ field }) => (
                      <FormControlLabel control={<Checkbox {...field} />} label="Wymagaj potwierdzenia" />
                    )}
                  />
                </FormGroup>
                <Box display="flex">
                  <ButtonAttachment handleAttachment={handleAttachment} />
                  <SubmitButton
                    sx={{ textTransform: 'none', width: 'auto', px: 10, ml: 5 }}
                    label="Wyślij"
                    isFormValid={isFormValid}
                  />
                </Box>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
};

const boxStyle = (isMinimized: boolean): SxProps => ({
  backgroundColor: 'white',
  borderRadius: 2,
  textAlign: 'center',
  boxShadow: '0px 4px 24px 4px rgba(81, 81, 81, 0.25)',
  height: '86vh',
  transition: 'all 0.3s',
  position: 'absolute',
  right: isMinimized ? '16px' : '50%',
  bottom: isMinimized ? 'calc(-86vh + 52px)' : '50%',
  width: isMinimized ? '30vw' : '62.6vw',
  transform: isMinimized ? undefined : 'translate(50%, 50%)',
  pointerEvents: 'all',
  cursor: isMinimized ? 'pointer' : 'auto',
  display: 'flex',
  flexDirection: 'column'
});

const modalStyle: SxProps = {
  position: 'fixed',
  height: '100vh',
  pointerEvents: 'none',
  zIndex: 1001
};

const headingStyle: SxProps = {
  width: '100%',
  backgroundColor: 'grey.800',
  display: 'flex',
  justifyContent: 'space-between',
  color: 'white',
  borderRadius: '8px 8px 0 0'
};
