import { Tabs, Tab } from '@mui/material';

interface NewsTabsProps {
  chosenTab: number;
  setChosenTab: (value: number) => void;
  totalCount?: number;
  totalToReadCount?: number;
}

export const NewsTabs = ({ chosenTab, setChosenTab, totalCount, totalToReadCount }: NewsTabsProps) => {
  const handleTabChange = (_event: unknown, value: number) => {
    setChosenTab(value);
  };

  return (
    <Tabs
      TabIndicatorProps={{
        style: { backgroundColor: '#1A1A1A' }
      }}
      value={chosenTab}
      onChange={handleTabChange}
    >
      <Tab sx={{ color: (theme) => theme.palette.common.black }} label={`Wszystkie ${totalCount}`} />
      <Tab sx={{ color: (theme) => theme.palette.common.black }} label={`Nieprzeczytane ${totalToReadCount}`} />
    </Tabs>
  );
};
