import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setCategory, selectNewMessageState } from 'redux/newMessage';

import { CategoryListConfig } from 'common/constants';

import { Tag } from 'common/components';

interface CategoryPickerProps {
  handleOnClick?: (category: number) => void;
}

export function CategoryPicker({ handleOnClick }: CategoryPickerProps) {
  const dispatch = useAppDispatch();
  const { category: selectedCategory } = useAppSelector(selectNewMessageState);

  return (
    <Box my={2} sx={{ display: 'flex' }}>
      {CategoryListConfig.map(({ id, categoryColor, label }) => (
        <Tag
          onClick={() => {
            if (handleOnClick) handleOnClick(id);
            dispatch(setCategory(id));
          }}
          color={categoryColor}
          label={label}
          sx={{ mr: (theme) => theme.spacing(1), fontSize: '1rem', px: (theme) => theme.spacing(2) }}
          key={`${id}`}
          variant={selectedCategory !== id ? 'outlined' : undefined}
        />
      ))}
    </Box>
  );
}
