import { Grid } from '@mui/material';
import { VerifyEmailData, VerifyEmail } from 'features/accounts';
import { useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useVerifyEmailMutation } from 'redux/api';
import { useAppDispatch } from 'redux/hooks';
import { showToast } from 'redux/toast';
import { RoutesDefinition } from 'routing/constants/RoutesDefinition';

export const VerifyEmailPage = () => {
  const dispatch = useAppDispatch();

  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');

  const [verifyEmail, { isLoading, isSuccess, error }] = useVerifyEmailMutation();
  const navigate = useNavigate();

  const handleSubmit: SubmitHandler<VerifyEmailData> = async (requestData) => {
    try {
      await verifyEmail(requestData);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(showToast({ severity: 'success', message: 'Aktywacja przebiegła pomyślnie' }));
      navigate(RoutesDefinition.login);
    }
  }, [navigate, isSuccess, dispatch]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ width: '100vw', height: '100vh' }}
      columns={{ xs: 4, md: 12 }}
    >
      <VerifyEmail token={token} onSubmit={handleSubmit} isProcessing={isLoading} error={error} />
    </Grid>
  );
};
