import { createSlice } from '@reduxjs/toolkit';
import { AuthAccount } from 'features/accounts';

import { RootState } from 'redux/store';

import { authApi } from './api';

interface AuthState {
  account: null | AuthAccount;
  isAuthenticated: boolean;
}

const initialState: AuthState = localStorage.getItem('user')
  ? JSON.parse(localStorage.getItem('user') as string)
  : {
      account: null,
      isAuthenticated: false
    };

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (_, { payload: account }) => {
      const isAuthenticated = true;

      localStorage.setItem('user', JSON.stringify({ account, isAuthenticated }));
      return {
        account,
        isAuthenticated
      };
    });
    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, () => {
      localStorage.removeItem('user');
      return initialState;
    });
  }
});

export default authSlice.reducer;

export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectAccount = (state: RootState) => state.auth.account;
