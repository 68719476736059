export const errors = {
  required: 'Pole jest wymagane',
  minChar: 'Hasło powinno zawierać przynajmniej 8 znaków',
  incorrectEmail: 'Niepoprawny email',
  incorrectPhone: 'Niepoprawny telefon',
  incorrectPassword: 'Niepoprawne hasło',
  includesLowerCase: 'Hasło powinno zawierać przynajmniej jedną małą literę',
  includesUpperCase: 'Hasło powinno zawierać przynajmniej jedną wielką literę',
  includesDigit: 'Hasło powinno zawierać przynajmniej jedną cyfrę',
  includesSpecialChar: 'Hasło powinno zawierać przynajmniej jeden znak specjalny'
};
