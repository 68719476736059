import { errors } from 'common/constants';
import { z } from 'zod';

export const forgetPasswordSchema = z.object({
  email: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const fieldValidationResult = z.string().nonempty(errors.required).email(errors.incorrectEmail).safeParse(val);

      if (!fieldValidationResult.success) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.incorrectEmail
        });
      }
    })
});

export type ForgetPasswordData = z.infer<typeof forgetPasswordSchema>;

export const verifyEmailSchema = z.object({
  token: z.string(),
  password: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const fieldValidationResult = z
        .string()
        .nonempty(errors.required)
        .min(8, { message: errors.minChar })
        .safeParse(val);

      const includesLowerCase = /[a-z]/.test(val);
      const includesUpperCase = /[A-Z]/.test(val);
      const includesDigit = /[\d]/.test(val);
      const includesSpecialChar = /[^\d\w\s]/.test(val);

      if (!includesLowerCase) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.includesLowerCase
        });
      }

      if (!includesUpperCase) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.includesUpperCase
        });
      }

      if (!includesDigit) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.includesDigit
        });
      }

      if (!includesSpecialChar) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.includesSpecialChar
        });
      }

      if (!fieldValidationResult.success) {
        ctx.addIssue(fieldValidationResult.error.issues[0]);
      }
    })
});

export type VerifyEmailData = z.infer<typeof verifyEmailSchema>;
