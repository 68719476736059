import { Box, CircularProgress, List, ListItem, Stack, TablePagination } from '@mui/material';
import Refresh from '@mui/icons-material/Refresh';

import { useAppSelector } from 'redux/hooks';
import { selectActiveMessageState } from 'redux/activeMessage';

import { mapCategoriesToColors } from 'common/utils/categories';

import { NewsEntry } from './NewsEntry';
import { AdminPanelMessage, InboxMessage } from 'features/messages';
import { SearchBox } from 'common/components';
import { NewsTabs } from './NewsTabs';

const ROWS_PER_PAGE = 10;

interface NewsEntriesListProps {
  panelEntries?: AdminPanelMessage[] | InboxMessage[];
  currentPage: number;
  totalCount: number;
  totalToReadCount?: number;
  totalPages: number;
  handleSearchChange: (value?: string) => void;
  handlePageChange: (value: number) => void;
  handleManualRefetch: () => void;
  handleEntryClick: (id: string) => void;
  isAdmin?: boolean;
  isToRead?: boolean;
  handleTabChange?: (value: boolean) => void;
  isLoading: boolean;
}

export const NewsEntriesList = ({
  panelEntries,
  currentPage,
  totalCount,
  totalToReadCount = 0,
  handleSearchChange,
  handlePageChange,
  handleManualRefetch,
  handleEntryClick,
  handleTabChange,
  isAdmin,
  isToRead,
  isLoading
}: NewsEntriesListProps) => {
  const { activeMessage } = useAppSelector(selectActiveMessageState);

  const handleChangePage = (_event: unknown, newPage: number) => {
    handlePageChange(newPage + 1);
  };

  const handleTabClick = (tab: number) => {
    if (handleTabChange) {
      handleTabChange(!!tab);
    }
  };

  return (
    <Stack
      borderTop={'2px solid'}
      borderColor={'grey.200'}
      sx={{ minWidth: '425px', maxWidth: '425px', height: '100%' }}
    >
      <Box sx={{ pt: 2, px: 3 }}>
        <SearchBox placeholder="Wyszukaj" isFullWidth formSubmitTrigger={handleSearchChange} />
        {!isAdmin && (
          <NewsTabs
            totalCount={totalCount}
            totalToReadCount={totalToReadCount}
            chosenTab={!isToRead ? 0 : 1}
            setChosenTab={(tab) => handleTabClick(tab)}
          />
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Refresh sx={refreshButtonStyle} onClick={handleManualRefetch} />
          <TablePagination
            component="div"
            count={isToRead ? totalToReadCount : totalCount}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            rowsPerPage={ROWS_PER_PAGE}
            rowsPerPageOptions={[ROWS_PER_PAGE]}
          />
        </Box>
      </Box>
      <List sx={{ height: '100%', overflowY: 'scroll' }}>
        {!isLoading &&
          panelEntries?.map((entry) => (
            <ListItem sx={{ pl: 3, pr: 1 }} key={entry.id}>
              <NewsEntry
                title={entry.title}
                categoryColor={mapCategoriesToColors(entry.category)}
                isAcceptanceMandatory={entry.isAcceptanceMandatory}
                content={entry.content}
                addedAt={entry.addedAt}
                isSeen={isAdmin ? true : (entry as InboxMessage).isSeen}
                isConfirmed={isAdmin ? true : (entry as InboxMessage).isConfirmed}
                isActive={entry.id === activeMessage?.id}
                handleSelection={() => handleEntryClick(entry.id)}
              />
            </ListItem>
          ))}
        {isLoading && <CircularProgress sx={{ mx: 'auto', display: 'block' }} />}
      </List>
    </Stack>
  );
};

const refreshButtonStyle = {
  color: '#454749',
  '&:hover': {
    cursor: 'pointer'
  }
};
