import { MUIDataTableColumnDef } from 'mui-datatables';

export const addressGroupsDataTableColumns: MUIDataTableColumnDef[] = [
  {
    name: 'id',
    label: 'ID Grupy'
  },
  {
    name: 'name',
    label: 'Nazwa'
  },
  {
    name: 'numberOfMembers',
    label: 'Członkowie'
  }
];
