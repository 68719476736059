import { MailOutline } from '@mui/icons-material';

export const CategoryListConfig = [
  {
    id: 0,
    isMain: true,
    label: 'Odebrane',
    categoryColor: '#FCE8E6',
    icon: {
      tagColor: '#000',
      component: MailOutline
    }
  },
  { id: 1, label: 'Ekspozycja', categoryColor: '#F2DFA8' },
  { id: 2, label: 'Rozliczenia', categoryColor: '#91DACC' },
  { id: 3, label: 'Wariant 3', categoryColor: '#B1E9FF' }
  // { id: 5, label: 'Wariant 4', categoryColor: '#FFB1B1' },
  // { id: 6, label: 'Wariant 5', categoryColor: '#E6B1FF' }
];
