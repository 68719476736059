import { Box, SxProps, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { calculateFileSize } from 'common/utils/fileSize';
interface AttachmentTileProps {
  fileName: string;
  fileSize?: number;
  showFullWidth?: boolean;
  handleRemoveAttachment?: (name: string) => void;
}

export const AttachmentTile = ({ fileName, fileSize, handleRemoveAttachment, showFullWidth }: AttachmentTileProps) => {
  return (
    <Box sx={AttachmentWrapperStyle}>
      <Box display="flex" justifyContent="flex-start" sx={{ maxWidth: showFullWidth ? '100%' : 'calc(100% - 30px)' }}>
        <Typography
          fontWeight="600"
          color="info.main"
          sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {fileName}
        </Typography>
        <Typography color="grey.600">{fileSize ? `(${calculateFileSize(fileSize)})` : ''}</Typography>
      </Box>
      {handleRemoveAttachment && (
        <CloseIcon
          sx={{
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          onClick={() => handleRemoveAttachment(fileName)}
        />
      )}
    </Box>
  );
};

const AttachmentWrapperStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: 'grey.200',
  maxWidth: '500px',
  width: 'fit-content',
  textDecoration: 'none',
  py: 1,
  px: 2,
  borderRadius: 2
};
