import { z } from 'zod';
import { errors } from 'common/constants';

export const messageCreationSchema = z.object({
  title: z.string().nonempty(errors.required),
  category: z.number().min(0).max(2),
  recipients: z.string().array().min(1, errors.required),
  attachments: typeof window === undefined ? z.any() : z.instanceof(File).array(),
  content: z.string().nonempty(errors.required),
  isAcceptanceMandatory: z.boolean()
});

export type MessageCreationData = z.infer<typeof messageCreationSchema>;
