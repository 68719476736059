import { Button, SxProps } from '@mui/material';

interface SubmitButtonProps {
  label: string;
  isFormValid: boolean;
  isProcessing?: boolean;
  sx?: SxProps;
}

export const SubmitButton = ({ label, isFormValid, isProcessing, sx }: SubmitButtonProps) => {
  return (
    <Button
      type="submit"
      disabled={isProcessing}
      sx={{
        width: '100%',
        typography: 'body1',
        fontWeight: '600',
        borderRadius: 2,
        backgroundColor: isFormValid ? 'grey.800' : 'grey.400',
        color: isFormValid ? 'common.white' : 'grey.600',
        '&:hover': {
          backgroundColor: 'grey.800',
          color: 'common.white'
        },
        ...sx
      }}
    >
      {label}
    </Button>
  );
};
