import { useCallback, useEffect, useMemo, useState } from 'react';

import { Grid, InputLabel, Typography } from '@mui/material';
import { useForm, FormProvider, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { groupCreationSchema, GroupCreationData } from 'common/components/Modals/types';
import { CancelButton, DataTable, FormInputField, SubmitButton } from 'common/components';
import { Account, accountsDataTableColumns, transformAccountsToDataTable } from 'features/accounts';
import { useLazyGetAccountsQuery } from 'redux/api';
interface GroupCreationFormProps {
  onSubmit: SubmitHandler<GroupCreationData>;
  isError?: boolean;
  isProcessing?: boolean;
}
export const GroupCreationForm = ({ onSubmit, isProcessing }: GroupCreationFormProps) => {
  const [activeIds, setActiveIds] = useState<string[]>([]);
  const [fetchAccountsTrigger] = useLazyGetAccountsQuery();
  const [accountsData, setAccountsData] = useState<Account[]>([]);
  const [manualTrigger, setManualTrigger] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>();

  const handleStateChangeWithTrigger = (action?: () => void) => {
    if (action && typeof action === 'function') {
      action();
    }
    setManualTrigger(true);
  };

  const fetchAccounts = useCallback(async () => {
    const response = await fetchAccountsTrigger({ search });
    if (response.data) {
      setAccountsData(response.data);
    }
  }, [fetchAccountsTrigger, search]);

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts, manualTrigger]);

  const formMethods = useForm<GroupCreationData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(groupCreationSchema),
    defaultValues: {
      name: '',
      users: []
    }
  });

  const handleGroupSelectionChange = (index: number) => {
    const ids = [...activeIds];
    if (!ids.includes(accountsData[index].id)) {
      ids.push(accountsData[index].id);
    } else {
      const indexOfId = ids.indexOf(accountsData[index].id);
      ids.splice(indexOfId, 1);
    }
    setActiveIds(ids);
    formMethods.setValue('users', ids, { shouldValidate: true });
  };

  const handleClearAvailableResults = () => {
    const availableIds = accountsData.map((account) => account.id);
    const ids = [...activeIds];
    availableIds.forEach((id) => {
      if (ids.includes(id)) {
        const indexOfId = ids.indexOf(id);
        ids.splice(indexOfId, 1);
      }
    });
    setActiveIds(ids);
  };

  const activeIndeces = useMemo(() => {
    const indeces: number[] = [];
    accountsData.forEach((account, index) => {
      if (activeIds.includes(account.id)) {
        indeces.push(index);
      }
    });
    return indeces;
  }, [accountsData, activeIds]);

  return (
    <Grid component="div" display="flex" flexDirection="column" sx={{ width: '100%' }}>
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit((data, e) => {
            e?.preventDefault();
            onSubmit(data);
          })}
        >
          <Grid container flexDirection="column" justifyContent="space-around" height="100%">
            <Grid item display="flex" flexDirection="column" sx={{ '& > div': { py: '20px' } }}>
              <FormInputField label="Nazwa grupy" name="name" type="text" />
              <InputLabel sx={{ textAlign: 'left' }}>Wybierz użytkowników</InputLabel>
              <Controller
                name="users"
                control={formMethods.control}
                render={() => (
                  <DataTable
                    handleSearchChange={(value?: string) =>
                      handleStateChangeWithTrigger(() => {
                        setSearch(value);
                      })
                    }
                    selectedRows={activeIndeces}
                    handlePageChange={(newPage: number) =>
                      handleStateChangeWithTrigger(() => {
                        setPage(newPage);
                      })
                    }
                    clearAvailableResults={handleClearAvailableResults}
                    handleSelectionChange={handleGroupSelectionChange}
                    totalCount={accountsData.length}
                    currentPage={page}
                    sx={{ padding: 0, borderTop: 'unset' }}
                    columns={accountsDataTableColumns}
                    isDeleteDisabled
                    rows={transformAccountsToDataTable(accountsData)}
                    rowsPerPage={5}
                    isModalView
                  />
                )}
              />
              <Typography
                variant="body1"
                textAlign="left"
                color={formMethods.formState.errors.users ? 'red' : 'inherit'}
              >
                Liczba wybranych użytkowników: {activeIds.length}
              </Typography>
              <Grid item display="flex" justifyContent="space-between">
                <CancelButton label="Anuluj" sx={{ ml: 5 }} />
                <SubmitButton
                  label="Dodaj grupę"
                  isFormValid={formMethods.formState.isValid}
                  sx={{ width: '60%' }}
                  isProcessing={isProcessing}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Grid>
  );
};
