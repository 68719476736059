import { Button, SxProps } from '@mui/material';
import { useAppDispatch } from 'redux/hooks';
import { openModal } from 'redux/modal';

interface CancelButtonProps {
  label: string;
  sx?: SxProps;
}

export const CancelButton = ({ label, sx }: CancelButtonProps) => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(openModal());
  };

  return (
    <Button
      sx={{
        typography: 'body1',
        color: 'grey.800',
        fontWeight: '600',
        '&:hover': {
          backgroundColor: 'transparent'
        },
        ...sx
      }}
      onClick={handleClose}
    >
      {label}
    </Button>
  );
};
