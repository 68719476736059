import { ModalTypes, GroupCreationModal, UserCreationModal, CreationModalProps } from 'common/components';

export const modalResolver = (modalType?: ModalTypes, sideEffects?: CreationModalProps) => {
  switch (modalType) {
    case 'user_creation_modal': {
      return { label: 'Nowy użytkownik', component: <UserCreationModal {...sideEffects} /> };
    }
    case 'group_creation_modal': {
      return { label: 'Nowa grupa', component: <GroupCreationModal {...sideEffects} /> };
    }
    default: {
      return { label: '', component: null };
    }
  }
};
