import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ResponseError } from 'common/types';
import { AuthAccount, AuthRequest } from 'features/accounts';
import { authHeaders } from 'redux/authHelpers';

const baseAuthQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URI,
  prepareHeaders: authHeaders
});

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseAuthQuery as BaseQueryFn<string | FetchArgs, unknown, ResponseError>,
  endpoints: (builder) => ({
    login: builder.mutation<AuthAccount, AuthRequest>({
      query: (body) => ({ url: 'accounts/authenticate', credentials: 'include', method: 'POST', body })
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: 'accounts/revoke-token',
        credentials: 'include',
        headers: {
          'content-type': 'application/json'
        },
        method: 'POST'
      })
    })
  })
});

export const { useLoginMutation, useLogoutMutation } = authApi;
