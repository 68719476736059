import { useState, useRef, useEffect } from 'react';
import MuiAutocomplete from '@mui/material/Autocomplete';
import { SxProps, TextField } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { useDebounce } from 'common/hooks';
import { AutocompleteOption } from 'common/types';

interface SingleOptionAutocompleteProps {
  inputName: string;
  label: string;
  searchFunction: (value: string) => void;
  options?: AutocompleteOption[];
}

export const SingleOptionAutocomplete = ({
  inputName,
  label,
  searchFunction,
  options
}: SingleOptionAutocompleteProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [currentValue, setCurrentValue] = useState<AutocompleteOption>();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const {
    field: { value: fieldValue, ref: inputRef },
    fieldState: { error }
  } = useController({
    name: inputName
  });

  const { setValue } = useFormContext();

  const debouncedSearchValue = useDebounce<string>(inputValue, 500);

  useEffect(() => {
    async function fetchOptions() {
      setIsLoading(true);
      if (debouncedSearchValue === '') {
        setIsLoading(false);
      } else {
        await searchFunction(debouncedSearchValue);
      }
      setIsLoading(false);
    }
    fetchOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  const handleOptionChange = (newOption: AutocompleteOption | null) => {
    if (newOption) {
      setCurrentValue(newOption);
      setValue(inputName, newOption.fieldUsedInRequest);
    } else {
      setValue(inputName, '');
    }
  };

  return (
    <MuiAutocomplete
      ref={ref}
      disableCloseOnSelect
      inputValue={inputValue}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      loading={isLoading}
      loadingText={'Pobieranie...'}
      sx={singleOptionRootStyling()}
      renderInput={(inputParams) => (
        <TextField
          error={!!error}
          helperText={!!error && error?.message}
          label={label}
          name={inputName}
          type="text"
          value={fieldValue}
          inputRef={inputRef}
          sx={singleOptionInputStyling()}
          inputMode="text"
          {...inputParams}
        />
      )}
      value={currentValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, newValue) => handleOptionChange(newValue)}
      getOptionLabel={(option) => (option ? option.primaryLabel : '')}
      options={options || []}
      noOptionsText="Brak wyników"
      filterOptions={(x) => x}
    />
  );
};

const singleOptionRootStyling = (): SxProps => {
  return {
    '&.Mui-focused': {
      '& label': {
        color: 'rgba(0, 0, 0, 0.6)'
      },
      '& .MuiInputBase-root': {
        '&:after': {
          transform: 'scaleX(1)',
          borderColor: '#454749'
        }
      }
    },
    'label.Mui-error': {
      color: '#DA0027'
    },
    padding: '0 !important',
    '& > div': {
      py: '20px'
    }
  };
};

const singleOptionInputStyling = (): SxProps => {
  return {
    '& fieldset': {
      display: 'none'
    },
    '& .MuiInputBase-input': {
      padding: '4px 0 5px !important'
    },
    '.MuiInputBase-root': {
      marginTop: '16px !important',
      padding: '0 !important',
      '&:before': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        left: 0,
        bottom: 0,
        content: '"\\00a0"',
        position: 'absolute',
        right: 0,
        transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        pointerEvents: 'none'
      },
      '&:after': {
        borderBottom: '2px solid #ED1C24',
        borderBottomColor: 'rgb(237, 28, 36)',
        left: 0,
        bottom: 0,
        content: '""',
        position: 'absolute',
        right: 0,
        transform: 'scaleX(0)',
        pointerEvents: 'none'
      },
      '&:hover': {
        '&:before': {
          borderBottom: '2px solid rgba(0, 0, 0, 0.87)'
        }
      },
      '&.Mui-error': {
        '&:after': {
          borderBottomColor: '#DA0027',
          transform: 'scaleX(1)'
        }
      }
    }
  };
};
