import { useState, useEffect } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Grid } from '@mui/material';
import { showToast } from 'redux/toast';
import { useAppDispatch } from 'redux/hooks';
import { SerializedError } from '@reduxjs/toolkit';

import { ResponseError } from 'common/types';
import { FormInputField, SubmitButton } from 'common/components';
import { VerifyEmailData, verifyEmailSchema } from 'features/accounts/types';

interface VerifyEmailFormProps {
  onSubmit: SubmitHandler<VerifyEmailData>;
  error?: ResponseError | SerializedError;
  isProcessing?: boolean;
  token: string | null;
}

export const VerifyEmailForm = ({ onSubmit, isProcessing, error, token }: VerifyEmailFormProps) => {
  const dispatch = useAppDispatch();
  const [isFormValid, setFormValid] = useState<boolean>(false);
  const formMethods = useForm<VerifyEmailData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(verifyEmailSchema),
    defaultValues: {
      password: '',
      token: ''
    }
  });

  useEffect(() => {
    if (formMethods.formState.isValid) {
      setFormValid(true);
    }
  }, [formMethods.formState, isFormValid]);

  useEffect(() => {
    if (token) {
      formMethods.setValue('token', token);
    }
  }, [formMethods, token]);

  useEffect(() => {
    if (error && 'data' in error) {
      dispatch(
        showToast({
          severity: 'error',
          message: error.data.message
        })
      );
    }
  }, [error, dispatch]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container flexDirection="column" width="100%">
          <FormInputField label="Hasło" name="password" type="password" sx={{ pb: 6 }} />
          <SubmitButton
            label="Aktywuj konto"
            isFormValid={isFormValid}
            isProcessing={isProcessing}
            sx={{
              backgroundColor: isFormValid ? 'primary.main' : 'grey.400',
              color: isFormValid ? 'common.white' : 'grey.600',
              borderRadius: 6,
              py: 1.5,
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'primary.main',
                color: 'common.white'
              }
            }}
          />
        </Grid>
      </form>
    </FormProvider>
  );
};
