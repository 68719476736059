import { Box, Checkbox, Popper, CircularProgress, SxProps } from '@mui/material';
import MuiAutocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { useDebounce } from 'common/hooks';
import { UnderlinedInput } from 'common/components';
import { useEffect, useRef, useState } from 'react';
import { AutocompleteOption } from 'common/types';

interface MultipleOptionsAutocompleteProps {
  label: string;
  placeholder: string;
  searchFunction: (query: string) => Promise<AutocompleteOption[]>;
  handleValueChange?: (options: AutocompleteOption[]) => void;
  defaultOptions?: AutocompleteOption[];
  className?: string;
  sx?: SxProps;
}

export const MultipleOptionsAutocomplete = ({
  label,
  placeholder,
  defaultOptions,
  searchFunction,
  handleValueChange,
  className,
  sx
}: MultipleOptionsAutocompleteProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<AutocompleteOption[]>([]);
  const [options, setOptions] = useState(defaultOptions || []);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const debouncedSearchValue = useDebounce<string>(inputValue, 500);
  const componentWidth = ref.current ? ref.current?.offsetWidth * 0.925 : 0;

  useEffect(() => {
    if (!isOpen) {
      setOptions([]);
    }
  }, [isOpen]);

  useEffect(() => {
    async function fetchOptions() {
      setIsLoading(true);
      if (debouncedSearchValue === '') {
        setIsLoading(false);
        return undefined;
      }

      const results = await searchFunction(debouncedSearchValue);
      setIsLoading(false);
      setOptions(results);
    }
    fetchOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  const handleOptionChange = (newOptions: AutocompleteOption[]) => {
    if (handleValueChange) {
      handleValueChange(newOptions);
    }
    setOptions(options);
    setValue(newOptions);
  };

  return (
    <MuiAutocomplete
      ref={ref}
      className={className}
      sx={{
        ...sx,
        color: 'grey.800',
        '& .MuiChip-root': {
          fontWeight: 500,
          fontSize: 16,
          color: 'common.black',
          px: 1,
          py: '22px',
          backgroundColor: 'transparent',
          borderRadius: 2,
          borderColor: 'grey.800',
          borderWidth: '1.5px',
          borderStyle: 'solid',

          '& svg': {
            color: 'common.black'
          }
        },
        '& .MuiInputBase-root': {
          py: 0
        }
      }}
      multiple
      disableCloseOnSelect
      inputValue={inputValue}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      loading={isLoading}
      loadingText={'Pobieranie...'}
      renderInput={(params) => {
        const inputParams: AutocompleteRenderInputParams = {
          ...params,
          InputProps: {
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }
        };
        return (
          <Box
            sx={{
              typography: 'subtitle1'
            }}
          >
            <UnderlinedInput {...inputParams} label={label} placeholder={placeholder} />
          </Box>
        );
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      value={value}
      onChange={(event, newValue: AutocompleteOption[]) => handleOptionChange(newValue)}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Box
            sx={{
              width: '100%',
              display: 'grid',
              alignItems: 'center',
              gridTemplateColumns: `repeat(${option.columns.length + 1}, 1fr)`
            }}
          >
            <Box>
              <Checkbox checked={selected} />
            </Box>
            {option.columns.map((column) => {
              const Component = column.customComponent;
              const content = Component ? <Component>{column.value}</Component> : column.value;

              return (
                <Box
                  sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignContent: 'center' }}
                  key={column.value + column.label}
                >
                  {content}
                </Box>
              );
            })}
          </Box>
        </li>
      )}
      options={options}
      noOptionsText="Brak wyników"
      getOptionLabel={(option) => option.primaryLabel}
      filterOptions={(x) => x}
      PopperComponent={(props) => <Popper {...props} style={{ width: `${componentWidth}px` }} />}
    />
  );
};
