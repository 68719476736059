import { Editor as WysigwEditor, EditorProps } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Box from '@mui/material/Box';
import theme from 'common/Theme';

export function MessageEditor(props: EditorProps) {
  const enabledOptions = [
    'inline',
    'blockType',
    'fontSize',
    // 'fontFamily',
    'list',
    'textAlign',
    'colorPicker',
    'link',
    // 'embedded',
    'emoji',
    // 'image',
    'remove',
    'history'
  ];

  const enabledInlineOptions = [
    'bold',
    'italic',
    'underline',
    'strikethrough'
    // 'monospace'
    // 'superscript',
    // 'subscript'
  ];
  return (
    <Box mt={1} mb={1} sx={{ height: '42vh' }}>
      <WysigwEditor
        toolbar={{
          options: enabledOptions,
          inline: {
            options: enabledInlineOptions
          }
        }}
        wrapperStyle={{
          height: '35vh'
        }}
        editorStyle={{
          height: '100%',
          border: `1px solid ${theme.palette.grey[200]}`,
          padding: `0 ${theme.spacing(2)}`
        }}
        {...props}
      />
    </Box>
  );
}
