import { InboxPage } from 'pages/InboxPage';
import { RouteProps } from 'react-router-dom';
import { RoutesDefinition } from './RoutesDefinition';

export const userRoutes: RouteProps[] = [
  {
    path: RoutesDefinition.inbox,
    element: <InboxPage />
  }
];
