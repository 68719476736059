import { FC, forwardRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { hideToast, selectToastSlice } from 'redux/toast';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ToastNotification: FC = () => {
  const { isOpen, severity, message } = useAppSelector(selectToastSlice);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(hideToast());
  };

  return (
    <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
};
