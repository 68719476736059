import { Grid, Typography } from '@mui/material';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { SerializedError } from '@reduxjs/toolkit';
import { showToast } from 'redux/toast';
import { useAppDispatch } from 'redux/hooks';

import { ResponseError } from 'common/types';
import {
  CancelButton,
  ChipInputButton,
  FormInputField,
  SubmitButton,
  UserCreationData,
  userCreationSchema
} from 'common/components';

import { SingleOptionAutocomplete } from 'common/components/Autocomplete';
import { useLazyGetPharmaciesQuery } from 'redux/api';
import { formatPharmaciesToAutocompleteOptions } from 'common/utils/autocomplete';
import { useEffect } from 'react';
import { Roles } from 'features/accounts';

interface UserCreationFormProps {
  onSubmit: SubmitHandler<UserCreationData>;
  error?: ResponseError | SerializedError;
  isError?: boolean;
  isProcessing?: boolean;
}

export const UserCreationForm = ({ onSubmit, isError, isProcessing, error }: UserCreationFormProps) => {
  const [fetchPharmacies, { data: pharmacies }] = useLazyGetPharmaciesQuery();
  const dispatch = useAppDispatch();

  const formMethods = useForm<UserCreationData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(userCreationSchema),
    defaultValues: {
      name: '',
      pharmacyId: '',
      email: '',
      phone: '',
      role: 0
    }
  });

  useEffect(() => {
    if (error && 'data' in error) {
      dispatch(
        showToast({
          severity: 'error',
          message: error.data.message
        })
      );
    }
  }, [isProcessing, isError, formMethods, error, dispatch]);

  const handlePharmaciesSearch = async (value: string) => {
    await fetchPharmacies({ visible: true, nick: value });
  };

  return (
    <Grid display="flex" flexDirection="column" sx={{ height: '100%', width: '100%' }}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)} style={{ height: '100%' }}>
          <Grid container flexDirection="column" justifyContent="space-around" height="100%">
            <Grid item display="flex" flexDirection="column" sx={{ '& > div': { py: '20px' } }}>
              <FormInputField label="Nazwa użytkownika" name="name" type="text" />
              <SingleOptionAutocomplete
                searchFunction={(value) => handlePharmaciesSearch(value)}
                label="Apteka"
                inputName="pharmacyId"
                options={formatPharmaciesToAutocompleteOptions(pharmacies)}
              />
              <FormInputField label="Adres e-mail" name="email" type="email" />
              <FormInputField label="Numer telefonu" name="phone" type="tel" />
            </Grid>
            <Grid item display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="body2" sx={{ pb: 2 }}>
                Rodzaj konta
              </Typography>
              <Grid display="flex" sx={{ width: '100%', '& > div': { mr: 2 } }}>
                <ChipInputButton btnValue={Roles.OWNER} name="role" label="Właściciel" variant="outlined" />
                <ChipInputButton btnValue={Roles.EMPLOYEE} name="role" label="Aptekarz" variant="outlined" />
              </Grid>
            </Grid>
            <Grid item display="flex" justifyContent="space-between">
              <CancelButton label="Anuluj" sx={{ ml: 5 }} />
              <SubmitButton
                label="Dodaj użytkownika"
                isFormValid={formMethods.formState.isValid}
                sx={{ width: '60%' }}
                isProcessing={isProcessing}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Grid>
  );
};
