export const calculateFileSize = (fileSizeInBytes: number) => {
  const kilobyteSize = 1024;

  if (fileSizeInBytes < kilobyteSize) {
    return fileSizeInBytes + 'bytes';
  } else if (fileSizeInBytes >= kilobyteSize && fileSizeInBytes < kilobyteSize * kilobyteSize) {
    return (fileSizeInBytes / kilobyteSize).toFixed(1) + 'KB';
  } else if (fileSizeInBytes >= kilobyteSize * kilobyteSize) {
    return (fileSizeInBytes / (kilobyteSize * kilobyteSize)).toFixed(1) + 'MB';
  }
};

export const checkIfNotExceedSize = (filesArray: Array<File>, sizeLimitInBytes: number, callback: () => void) => {
  filesArray.forEach((file) => {
    if (file.size > sizeLimitInBytes) {
      filesArray.splice(filesArray.indexOf(file), 1);
      callback();
    }
  });
};
