import { Box, Button, SxProps } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { DataTableMenu } from './DataTableMenu';
import { PolishGridTranslation } from './constants/localeTranslation';
import { AccountDataTableRow } from 'features/accounts';
import { AddressGroupDataTableRow } from 'features/addressGroups';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableIsRowCheck, MUIDataTableOptions } from 'mui-datatables';

export interface DataTableProps {
  rows: AccountDataTableRow[] | AddressGroupDataTableRow[];
  columns: MUIDataTableColumnDef[];
  rowsPerPage: number;
  isModalView?: boolean;
  buttonText?: string;
  handleButtonClick?: () => void;
  handleSelectionChange?: (index: number) => void;
  handleDeleteIconClick?: () => void;
  sx?: SxProps;
  handleSearchChange: (searchPhrase?: string) => void;
  // Pages control:
  handlePageChange?: (page: number) => void;
  currentPage: number;
  totalCount: number;
  // Rows control:
  isDeleteDisabled?: boolean;
  isSelectionDisabled?: boolean;
  expandableRows?: boolean;
  renderExpandableRow?:
    | ((
        rowData: string[],
        rowMeta: {
          dataIndex: number;
          rowIndex: number;
        }
      ) => React.ReactNode)
    | undefined;
  isRowExpandable?: ((dataIndex: number, expandedRows?: MUIDataTableIsRowCheck | undefined) => boolean) | undefined;
  selectedRows?: number[];
  clearAvailableResults?: () => void;
  serverSide?: boolean;
}

export const DataTable = ({
  rows,
  columns,
  rowsPerPage,
  isModalView,
  buttonText,
  handleButtonClick,
  handleSelectionChange,
  handleDeleteIconClick,
  handlePageChange,
  sx,
  isSelectionDisabled,
  isDeleteDisabled,
  handleSearchChange,
  currentPage,
  totalCount,
  expandableRows,
  renderExpandableRow,
  isRowExpandable,
  selectedRows,
  clearAvailableResults,
  serverSide
}: DataTableProps) => {
  const dataTableOptions: MUIDataTableOptions = {
    download: false,
    print: false,
    search: false,
    confirmFilters: false,
    viewColumns: false,
    rowsPerPage: rowsPerPage ? rowsPerPage : 10,
    rowsPerPageOptions: rowsPerPage ? [rowsPerPage] : [10],
    textLabels: PolishGridTranslation,
    selectToolbarPlacement: 'none',
    selectableRows: isSelectionDisabled ? 'none' : 'multiple',
    filter: 'false',
    // pages control
    onChangePage: (page) => {
      if (handlePageChange) {
        handlePageChange(page + 1);
      }
    },
    onRowSelectionChange: (currentRowsSelected) => {
      if (currentRowsSelected.length && handleSelectionChange) {
        handleSelectionChange(currentRowsSelected[0].dataIndex as number);
      } else if (!currentRowsSelected.length && clearAvailableResults) {
        clearAvailableResults();
      }
    },
    responsive: 'vertical',
    page: currentPage - 1,
    count: totalCount,
    expandableRows,
    renderExpandableRow,
    isRowExpandable,
    rowsSelected: selectedRows ? selectedRows : [],
    serverSide
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        pt: 3,
        pb: 2,
        px: 3,
        borderTop: '2px solid',
        borderColor: 'grey.200',
        overflowX: 'hidden',
        ...sx
      }}
    >
      <DataTableMenu
        handleSearchSubmit={handleSearchChange}
        isModalView={isModalView}
        buttonText={buttonText}
        handleButtonClick={handleButtonClick || (() => {})}
      />
      <Box sx={dataGridStyles(isModalView)}>
        <MUIDataTable title={''} options={dataTableOptions} data={rows} columns={columns} />
      </Box>
      {!isDeleteDisabled && (
        <Box
          sx={{
            height: 60,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right'
          }}
        >
          <Button onClick={handleDeleteIconClick}>
            <DeleteIcon
              sx={{
                color: 'grey.800',
                width: 30,
                marginRight: 1,
                '&:hover': {
                  cursor: 'pointer',
                  color: 'grey.600'
                }
              }}
            />
          </Button>
        </Box>
      )}
    </Box>
  );
};

type DataGrdStyles = (isModalView?: boolean) => SxProps;

const dataGridStyles: DataGrdStyles = () => ({
  '& .MuiPaper-root': {
    marginTop: '60px',
    border: '1px solid rgba(224, 224, 224, 1)',
    boxShadow: 'none',
    borderRadius: '4px',
    position: 'relative',
    '& thead': {
      borderRadius: '8px 8px 0 0',
      '& th': {
        backgroundColor: 'transparent',
        border: 'none'
      }
    },
    '& tbody tr:last-of-type td': {
      border: 'none'
    }
  },
  '& .MuiPaper-root > .MuiToolbar-regular': {
    display: 'none'
  },
  '& .MuiPaper-root > table:last-of-type': {
    position: 'absolute',
    top: '-50px',
    left: '0px',
    '& td': {
      padding: 0,
      border: 'none'
    }
  }
});
