import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from './store';

interface ModalState {
  isOpen: boolean;
  isMinimized: boolean;
}

const initialState: ModalState = {
  isOpen: false,
  isMinimized: false
};

export const messageCreationModalSlice = createSlice({
  name: 'messageCreationModal',
  initialState,
  reducers: {
    openMessageCreationModal: (state) => {
      state.isOpen = true;
    },
    closeMessageCreationModal: (state) => {
      state.isOpen = false;
    },
    showMessageCreationModal: (state) => {
      state.isMinimized = true;
    },
    hideMessageCreationModal: (state) => {
      state.isMinimized = false;
    },
    toggleMessageCreationModalOpen: (state) => {
      state.isOpen = !state.isOpen;
    },
    toggleMessageCreationModalMinimized: (state) => {
      state.isMinimized = !state.isMinimized;
    }
  }
});

export const {
  openMessageCreationModal,
  closeMessageCreationModal,
  showMessageCreationModal,
  hideMessageCreationModal,
  toggleMessageCreationModalOpen,
  toggleMessageCreationModalMinimized
} = messageCreationModalSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMessageCreationModalState = (state: RootState) => state.messageCreationModal;

export default messageCreationModalSlice.reducer;
