import { Chip } from '@mui/material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { getColorBasedOnLocalizedText } from 'features/accounts/types';

export const accountsDataTableColumns: MUIDataTableColumnDef[] = [
  { name: 'id', label: 'ID użytkownika' },
  {
    name: 'name',
    label: 'Nazwa'
  },
  {
    name: 'pharmacy',
    label: 'Apteka'
  },
  {
    name: 'email',
    label: 'Adres e-mail'
  }
];

export const nestedAccountsDataTableColumns: MUIDataTableColumnDef[] = [
  { name: 'id', label: 'ID użytkownika' },
  {
    name: 'name',
    label: 'Nazwa'
  },
  {
    name: 'email',
    label: 'Adres e-mail'
  },
  {
    name: 'role',
    label: 'Rola',
    options: {
      customBodyRender: (value) => {
        return <Chip sx={{ backgroundColor: `${getColorBasedOnLocalizedText(value)}` }} label={value} />;
      }
    }
  }
];
