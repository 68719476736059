import { AutocompleteOption } from 'common/types';
import {
  Account,
  AccountDataTableRow,
  getRoleNameBasedOnEnum,
  NestedAccount,
  NestedAccountDataTableRow
} from 'features/accounts/types';

export const transformAccountsToDataTable = (accounts: Account[]): AccountDataTableRow[] => {
  return accounts.map((account) => {
    return {
      id: account.id,
      name: account.name,
      pharmacy: account.pharmacyName,
      email: account.email
    };
  });
};

export const transformNestedAccountsToDataTable = (accounts: NestedAccount[]): NestedAccountDataTableRow[] => {
  return accounts.map((account) => {
    return {
      id: account.id,
      name: account.name,
      email: account.email,
      role: getRoleNameBasedOnEnum(account.role)
    };
  });
};

export const transformAccountToAutoCompleteOption = (accounts: Account[]): AutocompleteOption[] => {
  return accounts.map((account) => {
    return {
      id: account.id,
      fieldUsedInRequest: account.email,
      primaryLabel: `${account.name} ${account.pharmacyName ? account.pharmacyName : ''}`,
      columns: [
        {
          label: 'name',
          value: account.name
        },
        {
          label: 'email',
          value: account.email
        },
        {
          label: 'pharmacyName',
          value: account.pharmacyName
        }
      ]
    };
  });
};
