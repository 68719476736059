import { Sandbox } from 'common/Sandbox';
import { ForgotPasswordPage } from 'pages/ForgotPasswordPage';
import { LandingPage } from 'pages/LandingPage';
import { LoginPage } from 'pages/LoginPage';
import { VerifyEmailPage } from 'pages/VerifyEmailPage';
import { RouteProps } from 'react-router-dom';
import { RoutesDefinition } from './RoutesDefinition';

export const guestRoutes: RouteProps[] = [
  {
    path: RoutesDefinition.landing,
    element: <LandingPage />
  },
  {
    path: RoutesDefinition.sandbox,
    element: <Sandbox />
  },
  {
    path: RoutesDefinition.forgotPassword,
    element: <ForgotPasswordPage />
  },
  {
    path: RoutesDefinition.login,
    element: <LoginPage />
  },
  {
    path: RoutesDefinition.verifyEmail,
    element: <VerifyEmailPage />
  }
];
