import { Button } from '@mui/material';

import {
  CategoryList,
  ConfirmationModal,
  MessageCreationModal,
  ActiveMessageView,
  TopBarNavigation,
  NewsEntriesList
} from 'common/components';
import AddIcon from '@mui/icons-material/Add';
import { CategoryListConfig } from 'common/constants';
import { PanelLayout } from 'layouts';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { closeMessageCreationModal, openMessageCreationModal } from 'redux/messageCreationModal';
import { useLazyGetAdminMessagesQuery } from 'redux/api';
import { useCallback, useEffect, useState } from 'react';
import { selectMessageState, setMessages } from 'redux/messages';
import { setActiveMessage } from 'redux/activeMessage';
import { AdminPanelMessage } from 'features/messages';

export const AdminMessagesPanelPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { lastFetchDate, messages, totalCount, totalPages } = useAppSelector(selectMessageState);
  const [activeCategory, setActiveCategory] = useState(0);
  const [manualTrigger, setManualTrigger] = useState(false);
  const [search, setSearch] = useState<string>();
  const [fetchMessages, { isFetching }] = useLazyGetAdminMessagesQuery();

  const dispatch = useAppDispatch();

  const handleStateChangeWithTrigger = (action?: () => void) => {
    if (action && typeof action === 'function') {
      action();
    }
    setManualTrigger(true);
  };

  const handleMessagesFetch = useCallback(async () => {
    const response = await fetchMessages({ page: currentPage, search, category: activeCategory });
    if (response.data) {
      dispatch(setMessages({ ...response.data }));
    }
    setManualTrigger(false);
  }, [fetchMessages, currentPage, search, activeCategory, dispatch]);

  useEffect(() => {
    // TODO: Add lastFetchDate time validation
    if (!lastFetchDate || !messages || manualTrigger) {
      handleMessagesFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleMessagesFetch, manualTrigger]);

  const handleEntryClick = (id: string) => {
    if (messages) {
      const newActiveMessage = (messages as AdminPanelMessage[]).find((item) => item.id === id);
      if (newActiveMessage) {
        dispatch(setActiveMessage({ ...newActiveMessage }));
      }
    }
  };

  return (
    <PanelLayout
      isAdminRoute
      logoColumnComponents={
        <>
          <Button
            onClick={() => dispatch(openMessageCreationModal())}
            variant="outlined"
            color="inherit"
            sx={{ color: 'black', textTransform: 'none', mb: 1, borderRadius: 2, width: '100%' }}
          >
            <AddIcon sx={{ mr: 0.5 }} /> Utwórz
          </Button>
          <CategoryList
            activeCategory={activeCategory}
            handleCategoryChange={(value: number) => {
              setActiveCategory(value);
              setManualTrigger(true);
            }}
            categories={CategoryListConfig}
          />
        </>
      }
      topBarComponents={<TopBarNavigation />}
    >
      <NewsEntriesList
        isAdmin
        panelEntries={messages}
        currentPage={currentPage}
        totalCount={totalCount}
        totalPages={totalPages}
        handleManualRefetch={handleStateChangeWithTrigger}
        handleSearchChange={(value?: string) => handleStateChangeWithTrigger(() => setSearch(value))}
        handlePageChange={(value: number) => handleStateChangeWithTrigger(() => setCurrentPage(value))}
        handleEntryClick={(id: string) => handleEntryClick(id)}
        isLoading={isFetching}
      />
      <ActiveMessageView />
      <MessageCreationModal />
      <ConfirmationModal
        boldText="Czy chcesz zamknąć wiadomość?"
        regularText="Zamknięcie wiadomości spowoduje utratę całej wiadomości."
        highlightedButtonText="Zamknij i usuń wiadomość"
        regularButtonText="Nie zamykaj i zachowaj treść wiadomości"
        handleOnConfirm={() => dispatch(closeMessageCreationModal())}
      />
    </PanelLayout>
  );
};
