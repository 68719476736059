import { ConfirmationModal, CreationModal, DataTable, TopBarNavigation } from 'common/components';
import { PanelLayout } from 'layouts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useArchiveAddressGroupsMutation, useLazyGetAddressGroupsQuery } from 'redux/api';
import { AddressGroup, addressGroupsDataTableColumns, transformAddressGroupsToDataTable } from 'features/addressGroups';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { nestedAccountsDataTableColumns, transformNestedAccountsToDataTable } from 'features/accounts';
import { PolishGridTranslation } from 'common/components/DataTable/constants/localeTranslation';
import { Box, TableCell } from '@mui/material';
import { closeCreationModal, openCreationModal } from 'redux/creationModal';
import { useAppDispatch } from 'redux/hooks';
import { showToast } from 'redux/toast';

const ROWS_PER_PAGE = 10;

export const AdminAddressGroupsPanelPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [activeIds, setActiveIds] = useState<string[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState<string>();
  const [fetchAdressGroups] = useLazyGetAddressGroupsQuery();
  const [addressGroups, setAddressGroups] = useState<AddressGroup[]>([]);
  const [archiveGroupsTrigger] = useArchiveAddressGroupsMutation();
  const dispatch = useAppDispatch();

  const handleMessagesFetch = useCallback(async () => {
    const response = await fetchAdressGroups({ page: currentPage, search });
    if (response.data) {
      setAddressGroups(response.data.groups);
      setTotalCount(response.data.totalCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, search]);

  useEffect(() => {
    handleMessagesFetch();
  }, [handleMessagesFetch]);

  const handleDeleteIconClick = async () => {
    if (activeIds.length > 0) {
      await archiveGroupsTrigger({ groups: activeIds });
      dispatch(showToast({ severity: 'success', message: 'Pomyślnie zarchiwizowano grupy' }));
      await handleMessagesFetch();
    }
  };

  const handleGroupSelectionChange = (index: number) => {
    const ids = [...activeIds];
    if (!ids.includes(addressGroups[index].id)) {
      ids.push(addressGroups[index].id);
    } else {
      const indexOfId = ids.indexOf(addressGroups[index].id);
      ids.splice(indexOfId, 1);
    }
    setActiveIds(ids);
  };

  const handleClearAvailableResults = () => {
    const availableIds = addressGroups.map((group) => group.id);
    const ids = [...activeIds];
    availableIds.forEach((id) => {
      if (ids.includes(id)) {
        const indexOfId = ids.indexOf(id);
        ids.splice(indexOfId, 1);
      }
    });
    setActiveIds(ids);
  };

  const activeIndeces = useMemo(() => {
    const indeces: number[] = [];
    addressGroups.forEach((account, index) => {
      if (activeIds.includes(account.id)) {
        indeces.push(index);
      }
    });
    return indeces;
  }, [addressGroups, activeIds]);

  const nestedDataTableOptions: MUIDataTableOptions = {
    download: false,
    print: false,
    search: false,
    confirmFilters: false,
    viewColumns: false,
    textLabels: PolishGridTranslation,
    selectToolbarPlacement: 'none',
    selectableRows: 'none',
    filter: 'false'
  };

  return (
    <PanelLayout isAdminRoute topBarComponents={<TopBarNavigation />}>
      <CreationModal
        onSuccess={() => {
          dispatch(showToast({ severity: 'success', message: 'Pomyślnie stworzono nową grupę' }));
          dispatch(closeCreationModal());
          handleMessagesFetch();
        }}
      />
      <ConfirmationModal
        boldText="Czy na pewno chcesz zakończyć?"
        regularText="Zamknięcie formularza spowoduje utratę całej treści."
        highlightedButtonText="Zamknij i usuń formularz"
        regularButtonText="Nie zamykaj i zachowaj treść formularza"
        handleOnConfirm={() => dispatch(closeCreationModal())}
      />
      <DataTable
        selectedRows={activeIndeces}
        handleDeleteIconClick={handleDeleteIconClick}
        handlePageChange={(page: number) => setCurrentPage(page)}
        totalCount={totalCount}
        currentPage={currentPage}
        clearAvailableResults={handleClearAvailableResults}
        handleSearchChange={(value?: string) => setSearch(value)}
        handleButtonClick={() => dispatch(openCreationModal('group_creation_modal'))}
        handleSelectionChange={handleGroupSelectionChange}
        columns={addressGroupsDataTableColumns}
        rows={transformAddressGroupsToDataTable(addressGroups)}
        rowsPerPage={ROWS_PER_PAGE}
        isModalView={false}
        buttonText="Dodaj grupę"
        expandableRows
        serverSide
        renderExpandableRow={(rowData, rowMeta) => {
          const colSpan = rowData.length + 1;
          const addressGroup = addressGroups[rowMeta.rowIndex];
          return (
            <Box
              component="tr"
              sx={{
                '& > td': {
                  pt: 0
                },
                '& .MuiPaper-root': {
                  mt: '50px'
                }
              }}
            >
              <TableCell colSpan={colSpan}>
                <MUIDataTable
                  title={`Użytkownicy grupy ${addressGroup.name}`}
                  options={nestedDataTableOptions}
                  columns={nestedAccountsDataTableColumns}
                  data={transformNestedAccountsToDataTable(addressGroup.users)}
                />
              </TableCell>
            </Box>
          );
        }}
        isRowExpandable={(dataIndex) => {
          if (addressGroups[dataIndex].users && addressGroups[dataIndex].users.length == 0) {
            return false;
          }

          return true;
        }}
      />
    </PanelLayout>
  );
};
