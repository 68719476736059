import { InputLabel, SxProps } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';

interface AttachmentProps {
  handleAttachment: React.ChangeEventHandler<HTMLInputElement>;
}

export const ButtonAttachment = ({ handleAttachment }: AttachmentProps) => {
  return (
    <InputLabel sx={inputStyle}>
      <input id="attachedFile" name="file" type="file" aria-label="Attach" onChange={handleAttachment} multiple />
      <AttachFileIcon sx={{ width: 'auto', height: '40px', color: 'grey.800' }} />
    </InputLabel>
  );
};

const inputStyle: SxProps = {
  width: 'fit-content',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',

  '&:hover': {
    cursor: 'pointer'
  },

  'input[type="file"]': {
    display: 'none'
  }
};
