import { useMemo } from 'react';
import { selectCreationModalState } from 'redux/creationModal';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { GlobalModal, modalResolver } from 'common/components';
import { openModal } from 'redux/modal';

export interface CreationModalProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export const CreationModal = ({ onSuccess, onError }: CreationModalProps) => {
  const { isOpen, modalType } = useAppSelector(selectCreationModalState);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(openModal());
  };

  const { label, component } = useMemo(
    () => modalResolver(modalType, { onSuccess, onError }),
    [modalType, onError, onSuccess]
  );

  return (
    <GlobalModal maxWidth={40} variant={{ labelModal: label }} isOpen={isOpen} handleClose={handleClose}>
      {component}
    </GlobalModal>
  );
};
