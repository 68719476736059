import { Routes, Route } from 'react-router-dom';
import { adminRoutes } from './constants/adminRoutes';
import { guestRoutes } from './constants/guestRoutes';
import { userRoutes } from './constants/userRoutes';

function Routing(): JSX.Element {
  const authRoutes = [...adminRoutes, ...userRoutes];

  return (
    <Routes>
      {guestRoutes.map((singleRoute, index) => (
        <Route key={(singleRoute.path as string) || `route-${index}`} {...singleRoute} />
      ))}
      {authRoutes.map((singleRoute, index) => (
        <Route key={(singleRoute.path as string) || `route-${index}`} {...singleRoute} />
      ))}
    </Routes>
  );
}

export default Routing;
