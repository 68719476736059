import { Typography } from '@mui/material';
import { Recipient } from 'features/messages';
import { useEffect, useState } from 'react';

interface MessageViewRecipientsProps {
  recipients: Recipient[];
}

export const MessageViewRecipients = ({ recipients }: MessageViewRecipientsProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const slicedRecipients = recipients.length > 4 ? recipients.slice(0, 4) : recipients;

  const recipientsToRender = isExpanded ? recipients : slicedRecipients;

  useEffect(() => {
    setIsExpanded(false);
  }, [recipients]);

  return (
    <Typography
      fontSize={14}
      fontWeight={600}
      variant="h6"
      sx={{
        position: 'absolute',
        padding: 1,
        top: 0,
        left: -10,
        borderRadius: 4,
        backgroundColor: 'white',
        border: '2px solid',
        borderColor: isExpanded ? 'grey.200' : 'transparent'
      }}
    >
      Wysłano do:{' '}
      {recipientsToRender.map((recipient, index) => (
        <span style={{ fontWeight: 200 }} key={recipient.id}>
          {recipient.name} {recipient.pharmacyName ? `(${recipient.pharmacyName})` : ''}
          {index < recipientsToRender.length - 1 ? ', ' : ''}
        </span>
      ))}
      {recipients.length > 4 && (
        <span style={{ cursor: 'pointer' }} onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? ' zwiń' : ' zobacz więcej...'}
        </span>
      )}
    </Typography>
  );
};
