export enum RoutesDefinition {
  landing = '/',

  sandbox = 'sandbox',

  inbox = '/inbox',

  admin = '/admin/*',
  adminBasePath = 'admin/',
  adminMessagesPanel = 'messages',
  adminAddressGroupsPanel = 'address-groups',
  adminUsersPanel = 'users',

  login = '/login',
  forgotPassword = '/forgot-password',
  verifyEmail = '/verify-email'
}
