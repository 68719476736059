import { Link } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import { RoutesDefinition } from 'routing/constants/RoutesDefinition';
import { SerializedError } from '@reduxjs/toolkit';

import { LoginFormData } from 'features/login';
import { ResponseError } from 'common/types';
import { LoginForm } from './LoginForm';

interface LoginProps {
  onSubmit: SubmitHandler<LoginFormData>;
  error?: ResponseError | SerializedError;
  isProcessing?: boolean;
}

export const Login = ({ onSubmit, isProcessing, error }: LoginProps) => {
  return (
    <Grid container flexDirection="column" alignItems="center" justifyContent="center" xs={3} md={4}>
      <Typography variant="h3" fontWeight="600" pb={7}>
        Zaloguj się
      </Typography>
      <LoginForm onSubmit={onSubmit} isProcessing={isProcessing} error={error}>
        <Typography
          variant="body1"
          fontWeight="500"
          sx={{
            textDecoration: 'none',
            color: 'common.black',
            fontStyle: 'italic',
            textAlign: 'right',
            mb: 3,
            '& > a:visited': { color: 'common.black' }
          }}
        >
          <Link
            to={RoutesDefinition.forgotPassword}
            style={{
              textDecoration: 'none',
              color: 'common.black'
            }}
          >
            Zapomniałeś hasła?
          </Link>
        </Typography>
      </LoginForm>
    </Grid>
  );
};
