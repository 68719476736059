import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { SubmitHandler } from 'react-hook-form';
import { useLoginMutation } from 'redux/api';

import { Login, LoginFormData } from 'features/login';
import { RoutesDefinition } from 'routing/constants/RoutesDefinition';

export const LoginPage = () => {
  const [login, { isLoading, isSuccess, error }] = useLoginMutation();
  const navigate = useNavigate();

  const handleSubmit: SubmitHandler<LoginFormData> = async (requestData) => {
    try {
      await login(requestData);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(RoutesDefinition.inbox);
    }
  }, [navigate, isSuccess]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ width: '100vw', height: '100vh' }}
      columns={{ xs: 4, md: 12 }}
    >
      <Login onSubmit={handleSubmit} isProcessing={isLoading} error={error} />
    </Grid>
  );
};
