import { ReactNode } from 'react';
import { Box, Modal, SxProps, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface CreationModalProps {
  labelModal: string;
}

interface GlobalModalProps {
  children: ReactNode;
  maxWidth: number;
  isOpen: boolean;
  handleClose: () => void;
  variant?: CreationModalProps;
}

export const GlobalModal = ({ children, maxWidth, isOpen, handleClose, variant }: GlobalModalProps) => {
  return (
    <>
      {isOpen && (
        <Modal
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={modalStyle}
        >
          <Box sx={boxStyle(maxWidth, variant)}>
            <Box sx={labelWrapper}>
              {variant && (
                <Typography variant="h6" fontWeight="600" sx={{ ml: (theme) => theme.spacing(4) }}>
                  {variant?.labelModal}
                </Typography>
              )}
              <CloseIcon sx={closeButton} onClick={handleClose} />
            </Box>
            {children}
          </Box>
        </Modal>
      )}
    </>
  );
};

const boxStyle = (maxWidth: number, variant?: CreationModalProps): SxProps => ({
  backgroundColor: 'white',
  minWidth: `${maxWidth}vw`,
  borderRadius: 2,
  padding: variant ? 0 : 4,
  textAlign: 'center',
  position: 'relative'
});

const labelWrapper: SxProps = {
  display: 'flex',
  position: 'absolute',
  top: 24,
  left: 0,
  right: 0,
  bottom: 12,
  padding: 0,
  height: 50,
  borderBottom: '1px solid',
  borderColor: 'grey.200'
};

const closeButton: SxProps = {
  position: 'absolute',
  cursor: 'pointer',
  right: 24
};

const modalStyle: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};
