import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalTypes } from 'common/components/Modals/types';
import type { RootState } from './store';

interface ModalState {
  isOpen: boolean;
  modalType?: ModalTypes;
}

const initialState: ModalState = {
  isOpen: false,
  modalType: undefined
};

export const creationModalSlice = createSlice({
  name: 'creationModal',
  initialState,
  reducers: {
    openCreationModal: (state, action: PayloadAction<ModalTypes>) => {
      state.isOpen = true;
      state.modalType = action.payload;
    },
    closeCreationModal: (state) => {
      state.isOpen = false;
      state.modalType = undefined;
    },
    toggleCreationModal: (state) => {
      state.isOpen = !state.isOpen;
    }
  }
});

export const { openCreationModal, closeCreationModal, toggleCreationModal } = creationModalSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCreationModalState = (state: RootState) => state.creationModal;

export default creationModalSlice.reducer;
