import { Box, IconButton, InputBase } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useState } from 'react';

interface SearchBoxProps {
  placeholder: string;
  formSubmitTrigger: (value: string) => void;
  isFullWidth?: boolean;
}

export const SearchBox = ({ placeholder, formSubmitTrigger, isFullWidth }: SearchBoxProps) => {
  const [value, setValue] = useState<string>('');

  return (
    <Box
      sx={{
        width: isFullWidth ? '100%' : '80%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: '1.5px solid',
        borderColor: 'grey.400',
        borderRadius: 2
      }}
    >
      <IconButton
        onClick={() => formSubmitTrigger(value)}
        type="button"
        sx={{
          color: 'grey.800',
          width: '50px',
          height: '50px'
        }}
      >
        <Search />
      </IconButton>
      <InputBase
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
          e.stopPropagation();
          if (e.key === 'Enter') {
            formSubmitTrigger(value);
            e.preventDefault();
          }
        }}
        value={value}
        placeholder={placeholder}
        type="text"
        aria-label="search"
        autoFocus
        sx={{
          width: 'calc(100% - 50px)',
          '& > .MuiInputBase-input': {
            color: 'grey.800',
            typography: 'body1',
            fontWeight: 500,
            p: (theme) => theme.spacing(2)
          }
        }}
      />
    </Box>
  );
};
