import { CategoryList, ActiveMessageView, NewsEntriesList } from 'common/components';
import { CategoryListConfig } from 'common/constants';
import { InboxMessage } from 'features/messages';
import { PanelLayout } from 'layouts';
import { useState, useCallback, useEffect } from 'react';
import { setActiveMessage } from 'redux/activeMessage';
import { useLazyGetInboxMessagesQuery, useMarkMessageAsSeenMutation } from 'redux/api';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { changeIsToReadTabActive, markMessageAsSeen, selectMessageState, setMessages } from 'redux/messages';

export const InboxPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { lastFetchDate, messages, totalCount, totalPages, totalLeftToReadCount, isToReadTabActive } =
    useAppSelector(selectMessageState);
  const [activeCategory, setActiveCategory] = useState(0);
  const [manualTrigger, setManualTrigger] = useState(false);
  const [search, setSearch] = useState<string>();
  const [fetchMessages, { isLoading }] = useLazyGetInboxMessagesQuery();
  const [markMessageAsSeenMutation] = useMarkMessageAsSeenMutation();

  const dispatch = useAppDispatch();

  const handleStateChangeWithTrigger = (action?: () => void) => {
    if (action && typeof action === 'function') {
      action();
    }
    setManualTrigger(true);
  };

  const handleMessagesFetch = useCallback(async () => {
    const response = await fetchMessages({
      page: currentPage,
      search,
      category: activeCategory,
      toRead: isToReadTabActive
    });
    if (response.data) {
      dispatch(setMessages({ ...response.data }));
    }
    setManualTrigger(false);
  }, [fetchMessages, currentPage, search, activeCategory, isToReadTabActive, dispatch]);

  useEffect(() => {
    // TODO: Add lastFetchDate time validation
    if (!lastFetchDate || !messages || manualTrigger) {
      handleMessagesFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleMessagesFetch, manualTrigger]);

  const handleEntryClick = async (id: string) => {
    if (messages) {
      const newActiveMessageIndex = (messages as InboxMessage[]).findIndex((item) => item.id === id);
      if (newActiveMessageIndex !== -1) {
        dispatch(setActiveMessage({ ...messages[newActiveMessageIndex] }));
        if (!(messages[newActiveMessageIndex] as InboxMessage).isSeen) {
          dispatch(markMessageAsSeen({ id }));
          await markMessageAsSeenMutation({ id });
        }
      }
    }
  };

  return (
    <PanelLayout
      logoColumnComponents={
        <CategoryList
          activeCategory={activeCategory}
          handleCategoryChange={(category) => handleStateChangeWithTrigger(() => setActiveCategory(category))}
          categories={CategoryListConfig}
        />
      }
    >
      <NewsEntriesList
        isLoading={isLoading}
        isToRead={isToReadTabActive}
        panelEntries={messages}
        currentPage={currentPage}
        totalCount={totalCount}
        totalPages={totalPages}
        totalToReadCount={totalLeftToReadCount}
        handleManualRefetch={handleStateChangeWithTrigger}
        handleSearchChange={(value?: string) => handleStateChangeWithTrigger(() => setSearch(value))}
        handlePageChange={(value: number) => handleStateChangeWithTrigger(() => setCurrentPage(value))}
        handleEntryClick={(id: string) => handleEntryClick(id)}
        handleTabChange={(value: boolean) =>
          handleStateChangeWithTrigger(() => dispatch(changeIsToReadTabActive({ isToRead: value })))
        }
      />
      <ActiveMessageView />
    </PanelLayout>
  );
};
