import { MUIDataTableTextLabels } from 'mui-datatables';

export const PolishGridTranslation: MUIDataTableTextLabels = {
  body: {
    noMatch: 'Brak rekordów',
    toolTip: 'Sortuj',
    columnHeaderTooltip: (column) => `Sortuj dla ${column.label}`
  },
  pagination: {
    next: 'Następna strona',
    previous: 'Poprzednia strona',
    rowsPerPage: 'Rekordów na stronę:',
    displayRows: 'z'
  },
  toolbar: {
    search: 'Wyszukaj',
    downloadCsv: 'Pobierz plik CSV',
    print: 'Drukuj',
    viewColumns: 'Zobacz kolumny',
    filterTable: 'Filtruj tabelę'
  },
  filter: {
    all: 'Wszystkie',
    title: 'Filtry',
    reset: 'Wyczyść filtry'
  },
  viewColumns: {
    title: 'Pokaż kolumny',
    titleAria: 'Pokaż/ukryj kolumny'
  },
  selectedRows: {
    text: 'wybranych rekordów',
    delete: 'Usuń',
    deleteAria: 'Usuń wybrane rekordy'
  }
};
