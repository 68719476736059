import { Grid, Typography } from '@mui/material';
import { SubmitHandler } from 'react-hook-form';
import { SerializedError } from '@reduxjs/toolkit';

import { ResponseError } from 'common/types';
import { ForgotPasswordForm } from './ForgotPasswordForm';
import { ForgetPasswordData } from 'features/accounts/types';

interface ForgotPasswordProps {
  onSubmit: SubmitHandler<ForgetPasswordData>;
  error?: ResponseError | SerializedError;
  isProcessing?: boolean;
}

export const ForgotPassword = ({ onSubmit, isProcessing, error }: ForgotPasswordProps) => {
  return (
    <Grid container flexDirection="column" alignItems="center" justifyContent="center" xs={3} md={4}>
      <Typography variant="h3" fontWeight="600" pb={3}>
        Przypomnij hasło
      </Typography>
      <Typography variant="subtitle2" fontWeight="400" pb={6}>
        Wprowadź adres e-mail przypisany do Twojego konta.
      </Typography>
      <ForgotPasswordForm onSubmit={onSubmit} isProcessing={isProcessing} error={error} />
    </Grid>
  );
};
