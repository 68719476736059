import { Box } from '@mui/material';

import { CreationModalProps, GroupCreationData } from 'common/components';
import { useEffect } from 'react';
import { useCreateNewAddressGroupMutation } from 'redux/api';
import { GroupCreationForm } from './GroupCreationForm';

interface GroupCreationModalProps extends CreationModalProps {}

export const GroupCreationModal = ({ onSuccess, onError }: GroupCreationModalProps) => {
  const [createNewGroup, { isSuccess, isLoading, isError }] = useCreateNewAddressGroupMutation();

  const handleSubmit = async ({ name, users }: GroupCreationData) => {
    await createNewGroup({ name, users });
  };

  useEffect(() => {
    if (onSuccess && isSuccess) {
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  useEffect(() => {
    if (onError && isError) {
      onError();
    }
  }, [isError, onError]);

  return (
    <Box
      sx={{
        p: 4,
        mt: 6,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'center',
        width: '54vw',
        height: '80vh',
        overflow: 'auto'
      }}
    >
      <GroupCreationForm isProcessing={isLoading} isError={isError} onSubmit={handleSubmit} />
    </Box>
  );
};
