import { useEffect } from 'react';
import { Box } from '@mui/material';
import { SubmitHandler } from 'react-hook-form';
import { useCreateAccountMutation } from 'redux/api';
import { closeModal } from 'redux/modal';
import { useAppDispatch } from 'redux/hooks';

import { UserCreationData } from 'common/components/Modals/types';
import { UserCreationForm } from './UserCreationForm';
import { CreationModalProps } from 'common/components';

interface UserCreationModalProps extends CreationModalProps {}

export const UserCreationModal = ({ onSuccess, onError }: UserCreationModalProps) => {
  const dispatch = useAppDispatch();
  const [createAccount, { isError, isLoading, isSuccess, error }] = useCreateAccountMutation();

  const handleSubmit: SubmitHandler<UserCreationData> = async (requestData) => {
    try {
      await createAccount(requestData);
    } catch (err) {
      if (onError) {
        onError();
      }
      console.error(err);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(closeModal());
      if (onSuccess) {
        onSuccess();
      }
    }
  }, [isSuccess, dispatch, onSuccess]);

  return (
    <Box
      sx={{
        p: 4,
        mt: 6,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'center',
        width: '100%',
        height: '80vh'
      }}
    >
      <UserCreationForm onSubmit={handleSubmit} isError={isError} isProcessing={isLoading} error={error} />
    </Box>
  );
};
