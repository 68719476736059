import { AutocompleteOption } from 'common/types';
import { Pharmacy } from 'features/pharmacies';

export const formatPharmaciesToAutocompleteOptions = (pharmacies?: Pharmacy[]): AutocompleteOption[] | undefined => {
  if (!pharmacies) {
    return undefined;
  }
  return pharmacies.map((pharmacy) => ({
    id: pharmacy.id,
    primaryLabel: pharmacy.name,
    fieldUsedInRequest: pharmacy.id,
    columns: [{ label: 'Name', value: pharmacy.name }]
  }));
};
