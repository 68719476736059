import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ArchiveAddressGroupsRequest,
  CreateAddressGroupResponse,
  CreateOrUpdateAddressGroupRequest,
  GetAddressGroupsQueryParams,
  GetAddressGroupsResponse
} from 'features/addressGroups';
import { baseQueryWithReauth } from 'redux/authHelpers';

export const addressGroupsApi = createApi({
  reducerPath: 'addressGroups',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAddressGroups: builder.query<GetAddressGroupsResponse, GetAddressGroupsQueryParams>({
      query: (params) => ({ url: 'groups', params })
    }),
    createNewAddressGroup: builder.mutation<CreateAddressGroupResponse, CreateOrUpdateAddressGroupRequest>({
      query: (body) => ({
        url: 'groups',
        headers: {
          'content-type': 'application/json'
        },
        method: 'POST',
        body
      })
    }),
    updateExistingAddressGroup: builder.mutation<void, CreateOrUpdateAddressGroupRequest>({
      query: (body) => ({
        url: 'groups',
        headers: {
          'content-type': 'application/json'
        },
        method: 'PUT',
        body
      })
    }),
    archiveAddressGroups: builder.mutation<void, ArchiveAddressGroupsRequest>({
      query: (body) => ({
        url: 'groups',
        headers: {
          'content-type': 'application/json'
        },
        method: 'DELETE',
        body
      })
    })
  })
});

export const {
  useLazyGetAddressGroupsQuery,
  useArchiveAddressGroupsMutation,
  useCreateNewAddressGroupMutation,
  useUpdateExistingAddressGroupMutation
} = addressGroupsApi;
