import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminPanelMessage, InboxMessage } from 'features/messages';
import type { RootState } from './store';

interface MessagesState {
  lastFetchDate: string | undefined;
  messages: AdminPanelMessage[] | InboxMessage[] | undefined;
  totalCount: number;
  totalPages: number;
  totalLeftToReadCount?: number;
  totalLeftToReadPages?: number;
  isToReadTabActive: boolean;
}

const initialState: MessagesState = {
  lastFetchDate: undefined,
  messages: undefined,
  totalCount: 0,
  totalPages: 0,
  totalLeftToReadCount: 0,
  totalLeftToReadPages: 0,
  isToReadTabActive: false
};

type MessagesPayload = {
  messages: AdminPanelMessage[] | InboxMessage[] | undefined;
  totalCount: number;
  totalPages: number;
  totalLeftToReadCount?: number;
  totalLeftToReadPages?: number;
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<MessagesPayload>) => {
      state.messages = action.payload.messages;
      state.totalCount = action.payload.totalCount;
      state.totalPages = action.payload.totalPages;
      state.lastFetchDate = new Date().toString();
      state.totalLeftToReadCount = action.payload.totalLeftToReadCount;
      state.totalLeftToReadPages = action.payload.totalLeftToReadPages;
    },
    changeIsToReadTabActive: (state, action: PayloadAction<{ isToRead: boolean }>) => {
      state.isToReadTabActive = action.payload.isToRead;
    },
    markMessageAsSeen: (state, action: PayloadAction<{ id: string }>) => {
      state.messages = state.messages as InboxMessage[];
      const newActiveMessageIndex = state.messages.findIndex((item) => item.id === action.payload.id);
      const newActiveElement = state.messages[newActiveMessageIndex];
      if (state.messages && newActiveMessageIndex !== -1) {
        newActiveElement.isSeen = true;
        if (state.isToReadTabActive && !newActiveElement.isAcceptanceMandatory) {
          state.messages = state.messages.filter((messages) => messages.id !== newActiveElement.id);
        }
        if (state.totalLeftToReadCount && !newActiveElement.isAcceptanceMandatory) {
          state.totalLeftToReadCount -= 1;
        }
      }
    },
    markMessageAsConfirmedAndUpdateMessages: (state, action: PayloadAction<{ id: string }>) => {
      state.messages = state.messages as InboxMessage[];
      const newActiveMessageIndex = state.messages.findIndex((item) => item.id === action.payload.id);
      const newActiveElement = state.messages[newActiveMessageIndex];
      if (state.messages && newActiveMessageIndex !== -1) {
        newActiveElement.isConfirmed = true;
        if (state.isToReadTabActive && newActiveElement.isAcceptanceMandatory) {
          state.messages = state.messages.filter((messages) => messages.id !== newActiveElement.id);
        }
        if (state.totalLeftToReadCount) {
          state.totalLeftToReadCount -= 1;
        }
      }
    }
  }
});

export const { setMessages, markMessageAsSeen, changeIsToReadTabActive, markMessageAsConfirmedAndUpdateMessages } =
  messagesSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMessageState = (state: RootState) => state.messages;

export default messagesSlice.reducer;
