import { Button, Grid, SxProps, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { closeModal, selectModalState } from 'redux/modal';

import { GlobalModal } from 'common/components';

interface ConfirmationModalProps {
  boldText: string;
  regularText: string;
  highlightedButtonText: string;
  regularButtonText: string;
  handleOnConfirm?: () => void;
  handleOnClose?: () => void;
}

export const ConfirmationModal = ({
  boldText,
  regularText,
  highlightedButtonText,
  regularButtonText,
  handleOnConfirm,
  handleOnClose
}: ConfirmationModalProps) => {
  const isOpen = useAppSelector(selectModalState);
  const dispatch = useAppDispatch();

  const handleConfirmSelection = () => {
    if (handleOnConfirm) {
      handleOnConfirm();
    }
    dispatch(closeModal());
  };

  const handleCloseSelection = () => {
    if (handleOnClose) {
      handleOnClose();
    }
    dispatch(closeModal());
  };

  return (
    <GlobalModal maxWidth={26} isOpen={isOpen} handleClose={() => dispatch(closeModal())}>
      <Typography variant="h6" component="h2">
        {boldText}
      </Typography>
      <Typography sx={{ mt: 2 }}>{regularText}</Typography>
      <Grid container direction="column" spacing={2} sx={{ mt: 2 }}>
        <Grid item>
          <Button onClick={handleConfirmSelection} variant="contained" sx={greyButton}>
            {highlightedButtonText}
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={handleCloseSelection} variant="outlined" color="inherit" sx={{ color: 'grey.800' }}>
            {regularButtonText}
          </Button>
        </Grid>
      </Grid>
    </GlobalModal>
  );
};

const greyButton: SxProps = {
  color: 'white',
  backgroundColor: 'grey.800',
  '&:hover': {
    backgroundColor: 'grey.600'
  }
};
