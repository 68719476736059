import { useState } from 'react';

import { useController } from 'react-hook-form';
import { Input, InputLabel, SxProps, FormHelperText, FormControl, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
export interface FormInputProps {
  label: string;
  name: string;
  type: React.HTMLInputTypeAttribute;
  sx?: SxProps;
}

export const FormInputField = ({ label, type, name, sx }: FormInputProps) => {
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);
  const handlePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const {
    field: { ref, value, ...fieldProps },
    fieldState: { error }
  } = useController({
    name
  });

  return (
    <FormControl
      error={!!error}
      sx={{
        '& .MuiInputLabel-root.Mui-focused': {
          color: 'grey.800'
        },
        '& .MuiInputLabel-root.Mui-error': {
          color: 'error.main'
        },
        ...sx
      }}
    >
      <InputLabel>{label}</InputLabel>
      <Input
        {...fieldProps}
        name={name}
        type={type === 'password' ? (passwordVisibility ? 'text' : 'password') : type}
        value={value}
        inputRef={ref}
        sx={{
          width: '100%',
          '&:after': {
            borderColor: 'grey.800'
          }
        }}
        endAdornment={
          type === 'password' && (
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={handlePasswordVisibility} edge="end">
                {passwordVisibility ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }
      />
      {!!error && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  );
};
