import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminPanelMessage, InboxMessage } from 'features/messages';
import type { RootState } from './store';

interface ActiveMessageState {
  activeMessage: InboxMessage | AdminPanelMessage | undefined;
}

const initialState: ActiveMessageState = {
  activeMessage: undefined
};

export const activeMessageSlice = createSlice({
  name: 'activeMessage',
  initialState,
  reducers: {
    setActiveMessage: (state, action: PayloadAction<InboxMessage | AdminPanelMessage | undefined>) => {
      state.activeMessage = action.payload;
    },
    markMessageAsConfirmed: (state) => {
      if (state.activeMessage) {
        (state.activeMessage as InboxMessage).isConfirmed = true;
      }
    }
  }
});

export const { setActiveMessage, markMessageAsConfirmed } = activeMessageSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectActiveMessageState = (state: RootState) => state.activeMessage;

export default activeMessageSlice.reducer;
