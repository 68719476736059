import { Route, Routes } from 'react-router-dom';
import { adminRoutes } from 'routing/constants/adminRoutes';

export const AdminPage = () => (
  <>
    <Routes>
      {adminRoutes.map((singleRoute, index) => (
        <Route key={(singleRoute.path as string) || `route-${index}`} {...singleRoute} />
      ))}
    </Routes>
  </>
);
