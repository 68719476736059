import { Box, SxProps, Typography } from '@mui/material';
import ErrorRounded from '@mui/icons-material/ErrorRounded';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import { stripHtml } from 'string-strip-html';

const ENTRY_DESCRIPTION_LENGTH = 75;

interface NewsEntryProps {
  title: string;
  categoryColor: string;
  isAcceptanceMandatory: boolean;
  content: string;
  addedAt: string;
  isSeen: boolean;
  isConfirmed: boolean;
  isActive: boolean;
  handleSelection: () => void;
}

export const NewsEntry = ({
  title,
  categoryColor,
  isAcceptanceMandatory,
  content,
  addedAt,
  isSeen,
  isConfirmed,
  isActive,
  handleSelection
}: NewsEntryProps) => {
  const strippedContent = stripHtml(content);
  const shortenedContent =
    strippedContent.result.length > 75
      ? strippedContent.result.substring(0, ENTRY_DESCRIPTION_LENGTH) + '...'
      : strippedContent.result;

  return (
    <Box sx={entryContainerStyle(isSeen, isActive, isAcceptanceMandatory, isConfirmed)} onClick={handleSelection}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '275px' }}>
            {isAcceptanceMandatory && !isConfirmed && (
              <ErrorRounded
                sx={{ color: (theme) => theme.palette.grey[800], pr: (theme) => theme.spacing(2), width: 'unset' }}
              />
            )}
            <Typography variant="body1" sx={entryTitleStyle(isSeen)}>
              {title}
            </Typography>
          </Box>
          <Typography variant="body1" color="grey.800" sx={entryTimestampStyle(isSeen)}>
            {format(new Date(addedAt), 'dd MMM', { locale: pl })}
          </Typography>
        </Box>
        <Box sx={entrySeparator(categoryColor)} />
        <Typography variant="body2" color="grey.800" sx={{ fontSize: 14, margin: 0, fontWeight: 400 }}>
          {shortenedContent}
        </Typography>
      </Box>
    </Box>
  );
};

const entryContainerStyle = (
  isSeen: boolean,
  isActive: boolean,
  isAcceptanceMandatory: boolean,
  isConfirmed: boolean
): SxProps => ({
  boxShadow: '0px 4px 8px rgba(81, 81, 81, 0.25)',
  borderRadius: '6px 8px 8px 6px',
  borderLeft: `${!isSeen || (isAcceptanceMandatory && !isConfirmed) ? '6px solid #454749' : 'none'}`,
  padding: '16px',
  paddingLeft: `${!isSeen ? '10px' : '16px'}`,
  width: '100%',
  height: '110px',
  display: 'flex',
  backgroundColor: `${isActive ? '#EFF2F5' : 'white'}`,
  '&:hover': {
    cursor: 'pointer'
  }
});

const entryTitleStyle = (isSeen: boolean): SxProps => ({
  fontWeight: `${!isSeen ? '600' : '400'}`,
  lineHeight: 'unset',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});

const entryTimestampStyle = (isSeen: boolean): SxProps => ({
  fontWeight: `${!isSeen ? '600' : '400'}`,
  lineHeight: 'unset'
});

const entrySeparator = (labelColor: string): SxProps => ({
  backgroundColor: `${labelColor}`,
  borderRadius: '2px',
  margin: '4px 0',
  height: '4px',
  width: '40px'
});
