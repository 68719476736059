import { CircularProgress } from '@mui/material';
import { AuthRoles } from 'features/accounts';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectAccount } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { RoutesDefinition } from 'routing/constants/RoutesDefinition';

export const LandingPage = () => {
  const account = useAppSelector(selectAccount);
  const navigate = useNavigate();

  useEffect(() => {
    if (!account) {
      navigate(RoutesDefinition.login);
    } else if (account && account.role === AuthRoles.Admin) {
      navigate(RoutesDefinition.adminBasePath + RoutesDefinition.adminMessagesPanel);
    } else {
      navigate(RoutesDefinition.inbox);
    }
  }, [account, navigate]);

  return (
    <div>
      <CircularProgress />
    </div>
  );
};
