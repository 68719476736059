import { ConfirmationModal, DataTable, TopBarNavigation, CreationModal } from 'common/components';
import { PanelLayout } from 'layouts';
import { useAppDispatch } from 'redux/hooks';
import { closeCreationModal, openCreationModal } from 'redux/creationModal';
import { useCallback, useEffect, useState } from 'react';
import { useLazyGetAccountsQuery } from 'redux/api';
import { AccountDataTableRow, accountsDataTableColumns, transformAccountsToDataTable } from 'features/accounts';
import { showToast } from 'redux/toast';

export const AdminUsersPanelPage = () => {
  const dispatch = useAppDispatch();
  const [fetchAccountsTrigger] = useLazyGetAccountsQuery();
  const [accountsData, setAccountsData] = useState<AccountDataTableRow[]>([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>();

  const fetchAccounts = useCallback(async () => {
    const response = await fetchAccountsTrigger({ search });
    if (response.data) {
      setAccountsData(transformAccountsToDataTable(response.data));
    }
  }, [fetchAccountsTrigger, search]);

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  return (
    <PanelLayout isAdminRoute topBarComponents={<TopBarNavigation />}>
      <CreationModal
        onSuccess={() => {
          dispatch(showToast({ severity: 'success', message: 'Pomyślnie stworzono nowego użytkownika' }));
          dispatch(closeCreationModal());
          fetchAccounts();
        }}
      />
      <ConfirmationModal
        boldText="Czy na pewno chcesz zakończyć?"
        regularText="Zamknięcie formularza spowoduje utratę całej treści."
        highlightedButtonText="Zamknij i usuń formularz"
        regularButtonText="Nie zamykaj i zachowaj treść formularza"
        handleOnConfirm={() => dispatch(closeCreationModal())}
      />
      <DataTable
        handleSearchChange={(value?: string) => setSearch(value)}
        handleButtonClick={() => dispatch(openCreationModal('user_creation_modal'))}
        columns={accountsDataTableColumns}
        rows={accountsData}
        rowsPerPage={10}
        isModalView={false}
        buttonText="Dodaj użytkownika"
        isDeleteDisabled
        isSelectionDisabled
        currentPage={page}
        handlePageChange={(pageNr: number) => setPage(pageNr)}
        totalCount={accountsData.length}
      />
    </PanelLayout>
  );
};
