import { Chip, ChipProps } from '@mui/material';

export interface TagProps extends Omit<ChipProps, 'color'> {
  color?: string;
}

export function Tag({ color, sx, variant, ...props }: TagProps) {
  const isOutlined = variant === 'outlined';

  return (
    <Chip
      sx={{
        borderRadius: 2,
        backgroundColor: () => (isOutlined ? 'white' : `${color}`),
        border: `${color} solid 2px`,
        ...sx
      }}
      color="default"
      variant={isOutlined ? 'outlined' : undefined}
      {...props}
    />
  );
}
