import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon } from '@mui/material';

import { ReactComponent as TagIcon } from 'common/assets/icons/categoryTag.svg';

export interface ColorfulTagIconProps {
  tagColor: string;
  component?: React.ElementType<SvgIconComponent> | SvgIconComponent;
}

export const ColorfulTagIcon = ({ component, tagColor }: ColorfulTagIconProps) => {
  return (
    <SvgIcon
      component={component ? component : TagIcon}
      sx={{
        path: {
          fill: tagColor
        }
      }}
    />
  );
};
