import { Grid, Typography } from '@mui/material';
import { SubmitHandler } from 'react-hook-form';
import { SerializedError } from '@reduxjs/toolkit';

import { ResponseError } from 'common/types';
import { VerifyEmailForm } from './VerifyEmailForm';
import { VerifyEmailData } from 'features/accounts/types';

interface VerifyEmailProps {
  onSubmit: SubmitHandler<VerifyEmailData>;
  error?: ResponseError | SerializedError;
  isProcessing?: boolean;
  token: string | null;
}

export const VerifyEmail = ({ onSubmit, isProcessing, error, token }: VerifyEmailProps) => {
  return (
    <Grid item container flexDirection="column" alignItems="center" justifyContent="center" xs={3} md={4}>
      <Typography variant="h3" fontWeight="600" pb={3}>
        Aktywuj konto
      </Typography>
      <Typography variant="subtitle2" fontWeight="400" pb={6}>
        Utwórz hasło, aby aktywować nowe konto.
      </Typography>
      <VerifyEmailForm token={token} onSubmit={onSubmit} isProcessing={isProcessing} error={error} />
    </Grid>
  );
};
