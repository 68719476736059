import { Box, SxProps } from '@mui/material';
import { CSSProperties } from 'react';
import { NavLink } from 'react-router-dom';
import { RoutesDefinition } from 'routing/constants/RoutesDefinition';

export const TopBarNavigation = () => (
  <Box sx={wrapperStyling}>
    <NavLink style={({ isActive }) => linkStyling(isActive)} to={`/admin/${RoutesDefinition.adminMessagesPanel}`}>
      Wiadomości
    </NavLink>
    <NavLink style={({ isActive }) => linkStyling(isActive)} to={`/admin/${RoutesDefinition.adminAddressGroupsPanel}`}>
      Grupy
    </NavLink>
    <NavLink style={({ isActive }) => linkStyling(isActive)} to={`/admin/${RoutesDefinition.adminUsersPanel}`}>
      Użytkownicy
    </NavLink>
  </Box>
);

const wrapperStyling: SxProps = {
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  pl: 3,
  '& > a': {
    '&:after': {
      content: '" "',
      position: 'absolute',
      bottom: '-8px',
      left: '0px',
      width: '100%',
      height: '2px',
      backgroundColor: 'grey.800',
      opacity: 0,
      transition: '0.3s all'
    },
    '&.active, &:hover': {
      '&:after': {
        opacity: 1
      }
    }
  }
};

const linkStyling = (isActive: boolean): CSSProperties => {
  return {
    color: '#454749',
    fontWeight: isActive ? 600 : 400,
    fontFamily: 'Poppins',
    margin: '0 3rem 0 0',
    textDecoration: 'none',
    position: 'relative'
  };
};
