import { ReactNode, useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { useAppDispatch } from 'redux/hooks';
import { showToast } from 'redux/toast';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { LoginFormData, loginFormSchema } from 'features/login';
import { FormInputField, SubmitButton } from 'common/components';
import { ResponseError } from 'common/types';

interface LoginFormProps {
  onSubmit: SubmitHandler<LoginFormData>;
  error?: ResponseError | SerializedError;
  children?: ReactNode;
  isProcessing?: boolean;
}

export const LoginForm = ({ onSubmit, error, children, isProcessing }: LoginFormProps) => {
  const dispatch = useAppDispatch();
  const [isFormValid, setFormValid] = useState<boolean>(false);

  const formMethods = useForm<LoginFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  });

  useEffect(() => {
    if (formMethods.formState.isValid) {
      setFormValid(true);
    }
  }, [formMethods.formState, isFormValid]);

  useEffect(() => {
    if (error && 'data' in error) {
      dispatch(
        showToast({
          severity: 'error',
          message: error.data.message
        })
      );
    }
  }, [error, dispatch]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container flexDirection="column" width="100%" sx={{ '& > div': { mb: 6 } }}>
          <FormInputField label="Adres e-mail" name="email" type="email" />
          <FormInputField label="Hasło" name="password" type="password" />
          {children}
          <SubmitButton
            label="Zaloguj się"
            isFormValid={isFormValid}
            isProcessing={isProcessing}
            sx={{
              backgroundColor: isFormValid ? 'primary.main' : 'grey.400',
              color: isFormValid ? 'common.white' : 'grey.600',
              borderRadius: 6,
              py: 1.5,
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'primary.main',
                color: 'common.white'
              }
            }}
          />
        </Grid>
      </form>
    </FormProvider>
  );
};
