import { AddressGroup } from 'features/addressGroups';
import { AddressGroupDataTableRow } from 'features/addressGroups/types';

export const transformAddressGroupsToDataTable = (groups: AddressGroup[]): AddressGroupDataTableRow[] => {
  return groups.map((group) => {
    return {
      id: group.id,
      name: group.name,
      numberOfMembers: group.users.length
    };
  });
};
