import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { openModal } from 'redux/modal';
import { showToast } from 'redux/toast';
import { useAppDispatch } from 'redux/hooks';
import { useLoginMutation, useLogoutMutation } from 'redux/api';
import { selectIsAuthenticated } from 'redux/auth';

import {
  MultipleOptionsAutocomplete,
  CreationModal,
  DataTable,
  MessageCreationModal,
  SearchBox
} from 'common/components';
import { ConfirmationModal } from 'common/components/Modals/ConfirmationModal';

import { CategoryPicker } from 'features/messages/messageCreation/components/CategoryPicker';

import { mockedUsersSearch, dataRows } from './mockData';
import { MessageDemo } from './MessageDemo';
import { closeCreationModal, openCreationModal } from 'redux/creationModal';
import { closeMessageCreationModal, openMessageCreationModal } from 'redux/messageCreationModal';
import { accountsDataTableColumns } from 'features/accounts';

export function Sandbox() {
  const [login, { isLoading, error }] = useLoginMutation();
  const [logout] = useLogoutMutation();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const dispatch = useAppDispatch();

  const credentials = {
    email: 'user@example.com',
    password: 'stringst'
  };

  return (
    <Grid container spacing={12} sx={{ height: '100%', width: '100%', margin: '0' }}>
      <MessageCreationModal />
      <CreationModal />

      <ConfirmationModal
        boldText="Czy chcesz zamknąć wiadomość?"
        regularText="Zamknięcie wiadomości spowoduje utratę całej wiadomości."
        highlightedButtonText="Zamknij i usuń wiadomość"
        regularButtonText="Nie zamykaj i zachowaj treść wiadomości"
        handleOnConfirm={() => {
          dispatch(closeMessageCreationModal());
          dispatch(closeCreationModal());
        }}
      />
      <Grid item xs={6}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '600px' }}>
          <Typography variant="h1">Heading 1</Typography>
          <Typography variant="h2">Heading 2</Typography>
          <Typography variant="h3">Heading 3</Typography>
          <Typography variant="h4">Heading 4</Typography>
          <Typography variant="h5">Heading 5</Typography>
          <Typography variant="h6">Heading 6</Typography>

          <Typography variant="subtitle1">Subtitle 1</Typography>
          <Typography variant="subtitle2">Subtitle 2</Typography>

          <Typography variant="body1">Body 1</Typography>
          <Typography variant="body2">Body 2</Typography>
        </div>
      </Grid>
      <Grid item xs={6}>
        <SearchBox placeholder="Wyszukaj" formSubmitTrigger={(value) => console.log(value)} />
        <div>
          <MultipleOptionsAutocomplete
            searchFunction={(query: string) => mockedUsersSearch(query)}
            placeholder="Wyszukaj"
            label="Do:"
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button variant="contained" color="info">
            Info
          </Button>
          <Button variant="contained" color="success">
            Success
          </Button>
          <Button variant="contained" color="warning">
            Warning
          </Button>
          <Button variant="contained" color="error">
            Error
          </Button>
          <Button
            variant="contained"
            color="info"
            onClick={() => {
              dispatch(openModal());
            }}
          >
            Open modal
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              dispatch(
                showToast({
                  severity: 'error',
                  message: 'Test error toast'
                })
              );
            }}
          >
            Show toast
          </Button>
        </div>
        <CategoryPicker />
        <div style={{ position: 'fixed', top: '2rem', right: '2rem' }}>
          {!isAuthenticated ? (
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                await login(credentials);
                if (error) {
                  console.log(error);
                }
              }}
            >
              {isLoading ? 'Loading...' : 'Login as admin'}
            </Button>
          ) : (
            <Button
              onClick={() => {
                logout();
              }}
            >
              Logout
            </Button>
          )}
        </div>
      </Grid>

      <Grid container sx={{ paddingLeft: '6rem', marginTop: '2rem', justifyContent: 'center', gap: '1rem' }}>
        <Button variant="contained" color="primary" onClick={() => dispatch(openCreationModal('group_creation_modal'))}>
          Stwórz grupę
        </Button>
        <Button variant="contained" color="primary" onClick={() => dispatch(openCreationModal('user_creation_modal'))}>
          Stwórz użytkownika
        </Button>
        <Button variant="contained" color="primary" onClick={() => dispatch(openMessageCreationModal())}>
          Stwórz wiadomość
        </Button>
      </Grid>

      <Grid item xs={12} sx={{ height: '500px' }}>
        <MessageDemo />
      </Grid>
      <div style={{ height: '600px', width: '90%', margin: '0 auto' }}>
        <DataTable
          totalCount={120}
          currentPage={0}
          handleSearchChange={(val) => console.log(val)}
          handleDeleteIconClick={() => console.log('pooof')}
          handleSelectionChange={(ids) => console.log(ids)}
          handleButtonClick={() => console.log('Clicked')}
          columns={accountsDataTableColumns}
          rows={dataRows}
          rowsPerPage={5}
          isModalView={false}
          buttonText="Dodaj użytkownika"
        />
      </div>
    </Grid>
  );
}
