import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { SearchBox } from 'common/components';

export interface DataTableMenuProps {
  handleButtonClick: () => void;
  handleSearchSubmit: (value?: string) => void;
  isModalView?: boolean;
  buttonText?: string;
}

export const DataTableMenu = ({
  handleButtonClick,
  isModalView,
  buttonText,
  handleSearchSubmit
}: DataTableMenuProps) => {
  return (
    <Box sx={{ display: 'flex', height: '60px' }}>
      <SearchBox
        placeholder="Wyszukaj"
        isFullWidth={isModalView}
        formSubmitTrigger={(value?: string) => handleSearchSubmit(value)}
      />
      {!isModalView && (
        <Button
          variant="contained"
          sx={{
            minWidth: '225px',
            marginLeft: 'auto',
            borderRadius: '8px',
            backgroundColor: 'grey.800',
            '&:hover': {
              cursor: 'pointer',
              backgroundColor: 'grey.600'
            }
          }}
          startIcon={<AddIcon />}
          onClick={handleButtonClick}
        >
          {buttonText}
        </Button>
      )}
    </Box>
  );
};
